import React from 'react'

const Documentation = () => {
  return (
    <div className='dashboard__main'>
        <div className='dashboard__main--content'>
            Documentation
        </div>
    </div>
  )
}

export default Documentation