import { useStateContext } from '../../Context/ContextProvider'
import React from 'react'

/*export const CustomLink = ({ to, disabled, children }) =>
  disabled ? (
    <Link className="disabled-link" onClick={event => event.preventDefault()}>
      {children}
    </Link>
  ) : (
    <Link to={to}>{children}</Link>
  )*/

// https://stackoverflow.com/questions/32916786/react-children-map-recursively
const changePropsRecursive = (children, fn) => {
  return React.Children.map(children, child => {
    if (!React.isValidElement(child)) {
      return child
    }

    child = React.cloneElement(child, {
      ...fn(child),
      ...(child.props.children
        ? { children: changePropsRecursive(child.props.children, fn) }
        : {}),
    })

    return child
  })
}

export const SubscriptionGuard = ({ children, type }) => {
  const { isSubscription } = useStateContext()

  if (isSubscription === true) {
    return children
  } else if (isSubscription === false) {
    if (type === 'form') {
      return <fieldset disabled="disabled">{children}</fieldset>
    } else {
      return changePropsRecursive(children, child => ({
        disabled: true,
        onClick: e => e.preventDefault(),
        className:
          `disabled` +
          (child.props.className ? ` ${child.props.className}` : ''),
      }))
    }
  }
}
