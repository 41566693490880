const parseTTL = ttl => {
  ttl = Number(ttl)
  const days = Math.floor(ttl / (3600 * 24))
  const hours = Math.floor((ttl % (3600 * 24)) / 3600)
  const minutes = Math.floor((ttl % 3600) / 60)
  const seconds = Math.floor(ttl % 60)

  return {
    days,
    hours,
    minutes,
    seconds,
  }
}

const toTTL = ts => {
  if (!ts) return 0
  const days = Number(ts.days === '' ? ts.days : 0) * 24
  const hours = days + Number(ts.hours === '' ? ts.hours : 0)
  const minutes = hours * 60 + Number(ts.minutes === '' ? ts.minutes : 0)
  const seconds = minutes * 60 + Number(ts.seconds === '' ? ts.seconds : 0)
  return hours + minutes + seconds
}

export const prepareCacheForForm = cache => {
  if (!cache) return cache
  return {
    ...cache,
    /*headerForwarding: {
    ...cache.headerForwarding,
    value: cache.headerForwarding.values.join(' '),
  },
  cookieForwarding: {
    ...cache.cookieForwarding,
    value: cache.cookieForwarding.values.join(' '),
  },
  queryStringForwarding: {
    ...cache.queryStringForwarding,
    value: cache.queryStringForwarding.values.join(' '),
  },*/
    compression: cache.compression ? '1' : '0',
    ttl: parseTTL(cache.ttl),
  }
}

export const prepareCacheFormToSubmit = formData => {
  const input = {
    ...formData,
    httpMethods: formData.httpMethods || 'ALL',
    compression: formData.compression === '1',
    ttl: formData.ttl ? toTTL(formData.ttl) : 0,
    headerForwarding: {
      type: formData.headerForwarding?.type || 'DEFAULT',
      values: formData.headerForwarding?.values || [],
    },
    cookieForwarding: {
      type: formData.cookieForwarding?.type || 'NONE',
      values: formData.cookieForwarding?.values || [],
    },
    queryStringForwarding: {
      type: formData.queryStringForwarding?.type || 'NONE',
      values: formData.queryStringForwarding?.values || [],
    },
  }
  delete input.__typename
  delete input.precedence
  delete input.originName
  return input
}
