import React from 'react'
import Logo from '../Logo/Logo'
import Navigation from '../Navigation/Navigation'
import User from '../User/User'
import './Header.scss'

const Header = () => {
  return (
    <>
        <div className='header'>
          <Logo />
          <Navigation />
          <User />
        </div>
    </>
  )
}

export default Header