import React, { useState } from 'react'
// import './DistMonitoring.scss'

const StorageMonitoring = () => {
  return (
    <>
      <h3 className="content-subtitle mb-40">FlashEdge Storage</h3>
      <div className="input-wrap">
        <p className="content-text">Work in progress..</p>
      </div>
    </>
  )
}

export default StorageMonitoring
