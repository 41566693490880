import React, { useState } from 'react'
import LineChart, {
  MetricPeriods,
  MetricRange,
  calculatePeriod,
  calculateTimeRange,
} from '../../Charts/LineChart'
import './DistMonitoring.scss'
import { useStateContext } from '../../Context/ContextProvider'
import { MetricName, useMetricList } from './metricList'

const upperFirst = text => text.charAt(0).toUpperCase() + text.slice(1)
const lowerFirst = text => text.charAt(0).toLowerCase() + text.slice(1)

const metricsFromResult = (result, metricNames) => {
  const items = result.data?.metricList?.items

  return metricNames
    ? items?.filter(i =>
        metricNames
          .map(f =>
            lowerFirst(
              f
                .split('_')
                .map(t => upperFirst(t.toLowerCase()))
                .join('')
            )
          )
          .includes(i.id)
      )
    : items
}

const DEFAULT_DATE_RANGE = [
  ...calculateTimeRange(MetricRange.THREE_HOURS),
  MetricRange.THREE_HOURS,
]

const prepareRangeInput = dateRange => {
  if (!dateRange || !dateRange[0] || !dateRange[1]) return []
  const newRange = dateRange.slice(0)
  newRange[2] =
    MetricPeriods[newRange[2]] || calculatePeriod(newRange[0], newRange[1])
  return newRange
}

const DistMonitoring = () => {
  const { activeDist } = useStateContext()

  const [requestDateRange, setRequestDateRange] = useState(DEFAULT_DATE_RANGE)
  const [requestMetricPause, setRequestMetricPause] = useState(true)
  const [dataTransferDateRange, setDataTranserDateRange] =
    useState(DEFAULT_DATE_RANGE)
  const [dataTransferMetricPause, setDataTransferMetricPause] = useState(true)
  const [errorRateDateRange, setErrorRateDateRange] =
    useState(DEFAULT_DATE_RANGE)
  const [errorRateMetricPause, setErrorRateMetricPause] = useState(true)

  const [metricListResult, reexecuteMetricListQuery] = useMetricList(
    activeDist,
    ...prepareRangeInput(DEFAULT_DATE_RANGE),
    [
      MetricName.BYTES_DOWNLOADED,
      MetricName.BYTES_UPLOADED,
      MetricName.REQUESTS,
      MetricName.ERROR_RATE_4XX,
      MetricName.ERROR_RATE_5XX,
      MetricName.ERROR_RATE_TOTAL,
    ]
  )

  const [requestMetricListResult, reexecuteRequestMetricListResult] =
    useMetricList(
      activeDist,
      ...prepareRangeInput(requestDateRange),
      [MetricName.REQUESTS],
      requestMetricPause
    )

  const [dataTransferMetricListResult, reexecuteDataTransferMetricListResult] =
    useMetricList(
      activeDist,
      ...prepareRangeInput(dataTransferDateRange),
      [MetricName.BYTES_DOWNLOADED, MetricName.BYTES_UPLOADED],
      dataTransferMetricPause
    )

  const [errorRateMetricListResult, reexecuteErrorRateMetricListResult] =
    useMetricList(
      activeDist,
      ...prepareRangeInput(errorRateDateRange),
      [
        MetricName.ERROR_RATE_4XX,
        MetricName.ERROR_RATE_5XX,
        MetricName.ERROR_RATE_TOTAL,
      ],
      errorRateMetricPause
    )

  const requestMetrics = !requestMetricPause
    ? metricsFromResult(requestMetricListResult)
    : metricsFromResult(metricListResult, [MetricName.REQUESTS])

  const dataTransferMetrics = !dataTransferMetricPause
    ? metricsFromResult(dataTransferMetricListResult)
    : metricsFromResult(metricListResult, [
        MetricName.BYTES_DOWNLOADED,
        MetricName.BYTES_UPLOADED,
      ])

  const errorRateMetrics = !errorRateMetricPause
    ? metricsFromResult(errorRateMetricListResult)
    : metricsFromResult(metricListResult, [
        MetricName.ERROR_RATE_4XX,
        MetricName.ERROR_RATE_5XX,
        MetricName.ERROR_RATE_TOTAL,
      ])

  return (
    <>
      <div className="dashboard__main-form">
        <h3 className="content-subtitle">Monitoring</h3>
      </div>
      <div className="chart-containers monitoring-charts-wrapper">
        <LineChart
          label="Requests"
          metrics={requestMetrics}
          dateRange={requestDateRange}
          onChange={(...dateRange) => {
            setRequestMetricPause(false)
            setRequestDateRange(dateRange)
            reexecuteRequestMetricListResult({
              requestPolicy: 'network-only',
            })
          }}
          onReload={() =>
            reexecuteRequestMetricListResult({ requestPolicy: 'network-only' })
          }
        />
        <LineChart
          label="Data Transfer"
          metrics={dataTransferMetrics}
          dateRange={dataTransferDateRange}
          onChange={(...dateRange) => {
            setDataTransferMetricPause(false)
            setDataTranserDateRange(dateRange)
            reexecuteDataTransferMetricListResult({
              requestPolicy: 'network-only',
            })
          }}
          onReload={() =>
            reexecuteDataTransferMetricListResult({
              requestPolicy: 'network-only',
            })
          }
        />
        <LineChart
          label="Error Rate"
          metrics={errorRateMetrics}
          dateRange={errorRateDateRange}
          onChange={(...dateRange) => {
            setErrorRateMetricPause(false)
            setErrorRateDateRange(dateRange)
            reexecuteErrorRateMetricListResult({
              requestPolicy: 'network-only',
            })
          }}
          onReload={() =>
            reexecuteDataTransferMetricListResult({
              requestPolicy: 'network-only',
            })
          }
        />
      </div>
    </>
  )
}

export default DistMonitoring
