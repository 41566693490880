import React, { useEffect, useState } from 'react'
import { useStateContext } from '../../Context/ContextProvider'
import { useMutation } from 'urql'
import { buildMutationQuery } from '../../client'
import { Link, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { useStorageAPI } from '../../Context/ContextProvider/StorageApi'

const StorageAdvancedDelete = () => {
  const navigate = useNavigate()
  const { activeStorage } = useStateContext()
  const { currentStorage } = useStorageAPI(activeStorage)
  const [error, setError] = useState(null)

  const [storageDeleteResult, storageDelete] = useMutation(
    buildMutationQuery('storageDelete', ['success'])
  )

  useEffect(() => {
    setError(storageDeleteResult?.error?.message)
  }, [storageDeleteResult?.error?.message])

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, isDirty, isValid },
  } = useForm()

  const onSubmit = async input => {
    if (input.name !== currentStorage.name) {
      setError('The name you entered does not match the target name.')
      return false
    }
    const result = await storageDelete({
      input: {
        id: activeStorage,
        name: input.name,
      },
    })

    if (result?.data?.storageDelete?.success) {
      navigate('/storage', { state: { reload: true } })
    }
  }

  return (
    <form className="dashboard__main-form" onSubmit={handleSubmit(onSubmit)}>
      <h3 className="content-subtitle">Delete {currentStorage?.name} storage</h3>
      <p className="content-text">
        Deleting your FlashEdge storage will permanently delete it. Update the
        origin of your distribution before deleting your storage to avoid
        traffic interruption.
      </p>
      <p className="content-text">
        <span className="warning">Warning:</span> This process will completely
        remove this storage and its data.
      </p>
      <div className="input-wrap">
        <p className="content-text">To avoid unwanted deletion, please write the identifier of the storage you want to delete.</p>
        <label className="input-label" htmlFor="originDomain">
          Storage identifier
        </label>
        <input
          className="input md"
          type="text"
          name="name"
          id="name"
          required
          placeholder='Type the name of the storage'
          {...register('name', { required: true })}
        ></input>
      </div>
      <p className="content-text content-bold">
        Are you sure you want to delete this storage?
      </p>
      {error && (
            <p className={`content-text`}>
              <span className="warning">
                {error.replace('[GraphQL] ', '')}
              </span>
            </p>
          )}
      <button
        className="theme-btn form-btn"
        type="submit"
        disabled={!isDirty || !isValid || isSubmitting}
      >
        {storageDeleteResult.fetching && (
          <svg className="spinner" viewBox="0 0 50 50" width="24" height="24">
            <circle
              className="path"
              cx="25"
              cy="25"
              r="20"
              fill="none"
              strokeWidth="5"
            ></circle>
          </svg>
        )}
        Delete
      </button>
      <Link to={`/storage/${activeStorage}/advanced`}>
        <button className="theme-btn ml-auto-20" type="button">
          Go Back
        </button>
      </Link>
    </form>
  )
}

export default StorageAdvancedDelete
