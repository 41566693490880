import React, { useEffect, useRef, useState } from 'react'
import xCircle from '../../Assets/Images/x-circle.svg'

const PillInput = ({ name, id, onChange, value }) => {
  const myInput = useRef()

  const [pills, setPills] = useState([])

  useEffect(() => {
    if (value) {
      setPills(value)
    }
  }, [value])

  const createPill = value => {
    if (value) {
      pills.push(value)
      value = ''
      onChange(pills)
    }
  }

  const deletePill = (pill, number) => {
    pills.splice(number, 1)
    onChange(pills)
  }

  const handleInput = e => {
    e.preventDefault()
    if (e.key === 'Enter') {
      createPill(e.target.value)
      e.target.value = ''
    }
  }

  const handleAddBtn = () => {
    let element = myInput.current
    if (element.value.length < 1) {
      element.focus()
    } else {
      createPill(element.value)
      element.value = ''
    }
  }

  return (
    <div className="input-pill-tags">
      <ul className="input-pill__list">
        {pills?.map((pill, index) => {
          return (
            <li key={index} className="input-pill__item">
              {pill}
              <span
                className="input-pill__item-delete"
                onClick={() => {
                  deletePill(pill, index)
                }}
              >
                <img
                  src={xCircle}
                  className="input-pill__item-image"
                  alt="x-circle"
                />
              </span>
            </li>
          )
        })}
        <div className="input-pill__wrap">
          <input
            className="input-pill__input"
            ref={myInput}
            name={name}
            id={id}
            onKeyUp={handleInput}
          ></input>
          <button
            type="button"
            className="input-pill__btn-add"
            onClick={handleAddBtn}
          ></button>
        </div>
      </ul>
    </div>
  )
}

export default PillInput
