import { BillingForm } from './BillingForm'

const BillingInfo = () => (
  <>
    <div className="dashboard__main--content">
      <h2 className="billing__title">Billing Address</h2>
      <BillingForm />
    </div>
  </>
)

export default BillingInfo
