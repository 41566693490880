export const Buttons = ({ children, error }) => {
  return (
    <>
      {error && (
        <p className={`content-text`}>
          <span className="warning">
            {error.message.replace('[GraphQL] ', '')}
          </span>
        </p>
      )}
      <br />
      <div
        className="button-wrapper"
        style={{
          width: 'auto',
          columnGap: '10px',
        }}
      >
        {children}
      </div>
    </>
  )
}

export const ActionButton = props => {
  return (
    <>
      <button
        className={`theme-btn form-btn ${props?.className}`}
        type="button"
        onClick={props.onClick}
      >
        {props.fetching && (
          <svg className="spinner" viewBox="0 0 50 50" width="24" height="24">
            <circle
              className="path"
              cx="25"
              cy="25"
              r="20"
              fill="none"
              strokeWidth="5"
            ></circle>
          </svg>
        )}
        {props.label}
      </button>
    </>
  )
}
