import { gql, useQuery } from 'urql'

export const MetricName = Object.freeze({
  BYTES_DOWNLOADED: 'BYTES_DOWNLOADED',
  BYTES_UPLOADED: 'BYTES_UPLOADED',
  REQUESTS: 'REQUESTS',
  ERROR_RATE_4XX: 'ERROR_RATE_4XX',
  ERROR_RATE_5XX: 'ERROR_RATE_5XX',
  ERROR_RATE_TOTAL: 'ERROR_RATE_TOTAL',
})

export const MetricListQuery = gql`
  query DistributionMetricList(
    $distributionId: String!
    $period: Int!
    $start: AWSDateTime!
    $end: AWSDateTime!
    $metrics: [MetricName!]!
  ) {
    metricList(
      dimensions: { distributionId: $distributionId }
      period: $period
      start: $start
      end: $end
      metrics: $metrics
    ) {
      nextToken
      items {
        id
        label
        timestamps
        values
      }
      messages {
        code
        value
      }
    }
  }
`

export const useMetricList = (
  distributionId,
  // range,
  start,
  end,
  period,
  metrics,
  pause = false
) => {
  // const [start, end] = useMemo(() => calculateTimeRange(range), [range])

  return useQuery({
    query: MetricListQuery,
    variables: {
      distributionId,
      period,
      start,
      end,
      metrics,
    },
    pause,
  })
}
