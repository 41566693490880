import React from 'react'
import { useStateContext } from '../../Context/ContextProvider'
import { useMutation } from 'urql'
import { buildMutationQuery } from '../../client'
import { Link } from 'react-router-dom'
import { SubscriptionGuard } from '../SubscriptionGuard/SubscriptionGuard'

const DistCache = ({ limit }) => {
  const { currentDist, currentDistFetching, activeDist, setReloadCurrentDist } =
    useStateContext()

  const caches = currentDist?.caches

  const [cacheDeleteResult, cacheDelete] = useMutation(
    buildMutationQuery('cacheDelete', ['success'])
  )

  // TODO finish
  const handleDelete = async id => {
    const result = await cacheDelete({
      input: {
        id,
      },
    })

    if (result?.data?.cacheDelete?.success) {
      setReloadCurrentDist(true)
    }
  }

  return (
    <form className="dashboard__main-form">
      <h3 className="content-subtitle">Cache Behaviour</h3>
      <p className="content-text">
        A cache behavior enables you to customize the caching preferences for
        your FlashEdge distribution, determining what gets cached and what
        remains uncached. For instance, you gain the ability to designate
        specific directories, files, or file types from your origin that will be
        cached. Moreover, you have the option to define which HTML methods and
        headers are forwarded to your origin.
      </p>
      <p className="content-text">
        For instance, you gain the ability to designate specific directories,
        files, or file types from your origin that will be cached/not-cached.
      </p>
      <table className="table-wrapper">
        <thead className="table-head">
          <tr className="table-head-row">
            <th>Path Or File Types</th>
            <th>Origin</th>
            <th>Cache Policy</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody className="table-body">
          {/*queryResult.error && (
            <tr>
              <td colSpan="4">Oh no... {queryResult.error.message}</td>
            </tr>
          )*/}
          {currentDistFetching ? (
            <tr>
              <td colSpan="4">Loading...</td>
            </tr>
          ) : (
            caches &&
            caches.map((cache, index) => {
              return (
                <tr key={index}>
                  <td>{cache.path}</td>
                  <td>{cache.originName}</td>
                  <td>Cache {cache.policy?.toLowerCase()}</td>
                  <td>
                    <SubscriptionGuard>
                      <Link
                        to={`/distributions/${activeDist}/cache/${cache.id}/edit`}
                      >
                        <button className="theme-btn-table" type="button">
                          Edit
                        </button>
                      </Link>
                      {!cache.isDefault && (
                        <button
                          className="theme-btn-table inverted"
                          type="button"
                          onClick={() => handleDelete(cache.id)}
                        >
                          Delete
                        </button>
                      )}
                    </SubscriptionGuard>
                  </td>
                </tr>
              )
            })
          )}
        </tbody>
      </table>
      <SubscriptionGuard>
        <Link to={`/distributions/${activeDist}/cache/create`}>
          <button className="theme-btn" type="button">
            Create New Behaviour
          </button>
        </Link>
      </SubscriptionGuard>
    </form>
  )
}

export default DistCache
