import React from 'react'
import { Outlet } from 'react-router-dom'
import MainNavStorage from './MainNavStorage'

const Storage = () => {
  return (
    <>
      <MainNavStorage />
      <Outlet />
    </>
  )
}

export default Storage
