import './Billing.scss'
import BillingNav from '../BillingNav/BillingNav'
import { Outlet } from 'react-router-dom'

const Billing = () => {
  return (
    <>
      <div className="dashboard__main">
        <BillingNav />
        <Outlet />
      </div>
    </>
  )
}

export default Billing
