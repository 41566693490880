import { Outlet, useSearchParams } from 'react-router-dom'
import PageSpinner from '../PageSpinner/PageSpinner'
import './SubscriptionSuccess.scss'
import { gql, useQuery } from 'urql'
import { config } from '../../config'
import { useEffect } from 'react'
import { useStateContext } from '../../Context/ContextProvider'

const MeQuery = gql`
  query Me {
    me {
      account {
        subscription {
          type
          status
        }
      }
    }
  }
`

const SubscriptionSuccess = () => {
  const [searchParams] = useSearchParams()
  const successType = searchParams.get('type') || 'PLAN'
  const { setSpinner, setReloadMe } = useStateContext()
  const [{ data }, reexecuteQuery] = useQuery({
    query: MeQuery,
    pause: config.local,
  })

  const subscription = data?.me?.account?.subscription

  useEffect(() => {
    setSpinner(true)
    const success = () => {
      setSpinner(false)
      setReloadMe(true)
      window.location.href = '/billing'
    }

    if (
      subscription &&
      subscription.type === successType.toUpperCase() &&
      subscription.status === 'ACTIVE'
    ) {
      success()
    } else {
      setTimeout(() => {
        reexecuteQuery({ requestPolicy: 'network-only' })
      }, 3000)
    }
  }, [successType, subscription, setSpinner, setReloadMe, reexecuteQuery])

  return (
    <>
      <PageSpinner type="panel">
        <h3 className="success-title">
          Thank you, your subscription has been activated.
        </h3>
        <span style={{ color: '#676767' }}>
          You will be redirected in a moment.
        </span>
      </PageSpinner>
      <Outlet />
    </>
  )
}

export default SubscriptionSuccess
