import { forwardRef, useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import './DateRangePicker.scss'

export const DateRangePicker = ({ start: s, end: e, onChange }) => {
  /*const [dateRange, setDateRange] = useState([null, null])
  const [startDate, endDate] = dateRange*/
  const [start, setStart] = useState(s)
  const [end, setEnd] = useState(e)

  useEffect(() => {
    if (s) {
      setStart(s)
    }
  }, [setStart, s])
  useEffect(() => {
    if (e) {
      setEnd(e)
    }
  }, [setEnd, e])

  const CalendarButton = forwardRef(({ value, onClick }, ref) => (
    <span onClick={onClick} ref={ref}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 17C0 18.7 1.3 20 3 20H17C18.7 20 20 18.7 20 17V9H0V17ZM17 2H15V1C15 0.4 14.6 0 14 0C13.4 0 13 0.4 13 1V2H7V1C7 0.4 6.6 0 6 0C5.4 0 5 0.4 5 1V2H3C1.3 2 0 3.3 0 5V7H20V5C20 3.3 18.7 2 17 2Z"
          fill="#E84F1C"
        />
      </svg>
    </span>
  ))
  return (
    <DatePicker
      showPopperArrow={false}
      startDate={start}
      endDate={end}
      selectsRange={true}
      isClearable={true}
      showMonthDropdown
      showYearDropdown
      maxDate={new Date()}
      onChange={dates => {
        setStart(dates[0])
        setEnd(dates[1])
        if (dates[0] && dates[1]) {
          onChange(...dates)
        }
        // setDateRange(dates)
      }}
      // shouldCloseOnSelect={false}
      customInput={<CalendarButton />}
      monthsShown={2}
      renderCustomHeader={({
        monthDate,
        customHeaderCount,
        decreaseMonth,
        increaseMonth,
      }) => (
        <div>
          <button
            aria-label="Previous Month"
            className={
              'react-datepicker__navigation react-datepicker__navigation--previous'
            }
            style={customHeaderCount === 1 ? { visibility: 'hidden' } : null}
            onClick={decreaseMonth}
          >
            <span
              className={
                'react-datepicker__navigation-icon react-datepicker__navigation-icon--previous'
              }
            >
              {'<'}
            </span>
          </button>
          <span className="react-datepicker__current-month">
            {monthDate.toLocaleString('en-US', {
              month: 'long',
              year: 'numeric',
            })}
          </span>
          <button
            aria-label="Next Month"
            className={
              'react-datepicker__navigation react-datepicker__navigation--next'
            }
            style={customHeaderCount === 0 ? { visibility: 'hidden' } : null}
            onClick={increaseMonth}
          >
            <span
              className={
                'react-datepicker__navigation-icon react-datepicker__navigation-icon--next'
              }
            >
              {'>'}
            </span>
          </button>
        </div>
      )}
    />
  )
}
