import './MainContentDist.scss'
import { Outlet } from 'react-router-dom'

const MainContentDist = () => {
  return (
    <div className="dashboard__main--content">
      <Outlet />
    </div>
  )
}

export default MainContentDist
