import React from 'react'
import { useStateContext } from '../../Context/ContextProvider'
import { useMutation } from 'urql'
import { buildMutationQuery } from '../../client'
import { Link, useNavigate } from 'react-router-dom'

const InvalidateAdvanced = () => {
  const { activeDist } = useStateContext()
  const navigate = useNavigate()

  const [distributionInvalidateResult, distributionInvalidate] = useMutation(
    buildMutationQuery('distributionInvalidate', ['success'])
  )

  const handleResetCache = async () => {
    const result = await distributionInvalidate({
      input: {
        id: activeDist,
      },
    })

    if (result?.data?.distributionInvalidate?.success) {
      navigate(`/distributions/${activeDist}/advanced/`)
    }
  }

  return (
    <form className="dashboard__main-form" onSubmit={e => e.preventDefault()}>
      <h3 className="content-subtitle">Invalidate Cache</h3>
      <p className="content-text">
        This function will invalidate the files from FlashEdge caches. The next
        time a viewer requests the file, FlashEdge fetches the latest version of
        the file from your origin.
      </p>
      <p className="content-text">
        It may take up to 10 minutes to clear everything from the FlashEdge
        cache.
      </p>

      <p className="content-text content-bold">
        Are you sure you want to remove everything from FlashEdge caches?
      </p>

      {distributionInvalidateResult.error && (
        <p className={`content-text`}>
          <span className="warning">
            {distributionInvalidateResult.error.message.replace(
              '[GraphQL] ',
              ''
            )}
          </span>
        </p>
      )}
      <div className="button-wrapper">
        <button
          className="theme-btn form-btn"
          type="button"
          onClick={handleResetCache}
          disabled={distributionInvalidateResult.fetching}
        >
          {distributionInvalidateResult.fetching && (
            <svg className="spinner" viewBox="0 0 50 50" width="24" height="24">
              <circle
                className="path"
                cx="25"
                cy="25"
                r="20"
                fill="none"
                strokeWidth="5"
              ></circle>
            </svg>
          )}
          Reset Cache
        </button>
        <Link to={`/distributions/${activeDist}/advanced/`}>
          <button className="theme-btn ml-auto-20" type="button">
            Go Back
          </button>
        </Link>
      </div>
    </form>
  )
}

export default InvalidateAdvanced
