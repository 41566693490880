import React from 'react';
import './BottomBar.scss'

const BottomBar = () => {
  return (
    <div className="bottom-bar">
      <div className="bottom-links">
      <a href="https://forms.gle/Z1aVTCHzMYER7TuF6" target="_blank" rel="noopener noreferrer">Request a fix or feature</a>
      <a href="https://forms.gle/CivVH23mnPWvDjFR8" target="_blank" rel="noopener noreferrer">Feedback</a>
      </div>
    </div>
  );
};

export default BottomBar;

