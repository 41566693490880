import React from 'react';
import './NavItem.scss';

const NavItem = ({ href, name, openInNewTab }) => {
  const linkProps = openInNewTab ? { href, target: '_blank', rel: 'noopener noreferrer' } : { href };

  return (
    <li className="header-nav__list-item">
      <a {...linkProps} className="header-nav__list-item-link">
        {name}
      </a>
    </li>
  );
};

export default NavItem;
