import { NavLink } from 'react-router-dom'

const BillingNav = () => {
  return (
    <div className="dashboard__main--navigation">
      <ul className="dashboard__main--navigation-list">
        <li>
          <NavLink
            to="/billing"
            end
            className="dashboard__main--navigation-list-item"
          >
            Subscription Details
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/billing/info"
            end
            className="dashboard__main--navigation-list-item"
          >
            Billing Info
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/billing/invoices"
            className="dashboard__main--navigation-list-item"
          >
            Invoices
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/billing/access-keys"
            className="dashboard__main--navigation-list-item"
          >
            Access Keys
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/billing/advanced"
            className="dashboard__main--navigation-list-item"
          >
            Advanced
          </NavLink>
        </li>
      </ul>
    </div>
  )
}

export default BillingNav
