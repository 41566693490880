import './SmsVerification.scss'
import { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useMutation, useQuery } from 'urql'
import { buildMutationQuery } from '../../client'
import { useStateContext } from '../../Context/ContextProvider'
import { useNavigate } from 'react-router-dom'

// alt. ^(\+|00)[1-9][0-9 \-\(\)\.]{7,32}$
// sec. /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,5})|(\(?\d{2,6}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/
const phoneRx = /^\+(\d{1,3})(\d{4,14})$/

const sanitizePhone = input => input.replaceAll(' ', '').replaceAll('-', '')

const PhoneForm = ({ me, setSession, setPhone }) => {
  console.log(setSession)
  const [error, setError] = useState(null)

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, isDirty, isValid },
  } = useForm()

  const [userMfaUpdateResult, userMfaUpdate] = useMutation(
    buildMutationQuery('userMfaUpdate', ['success', 'session'])
  )

  useEffect(() => {
    if (userMfaUpdateResult?.error?.message) {
      const errText = userMfaUpdateResult.error.message.replace(
        '[GraphQL] ',
        ''
      )
      setError(errText)
    }
  }, [userMfaUpdateResult?.error?.message])

  const onSubmit = async input => {
    const sanitized = sanitizePhone(input.phone)
    if (!phoneRx.test(sanitized)) {
      return setError('Invalid phone number format')
    }
    setPhone(sanitized)
    const result = await userMfaUpdate({
      input: {
        id: me.id,
        phone: sanitized,
        password: input.password.trim(),
      },
    })

    if (result?.data?.userMfaUpdate?.success) {
      setSession(result.data.userMfaUpdate.session)
    }
  }

  return (
    <>
      <p className="content-text">
        Please enter your phone number <br />
        to which the verification code will be sent.*
      </p>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="input-wrap">
          <input
            className="input"
            type="text"
            placeholder="Phone number (+44 AAA NXX XXXX)"
            onKeyDown={() => setError(null)}
            {...register('phone', { required: true })}
          ></input>
        </div>
        <div className="input-wrap">
          <input
            className="input"
            type="password"
            placeholder="Password"
            onKeyDown={() => setError(null)}
            {...register('password', { required: true })}
          ></input>
        </div>
        <div className="button-wrapper">
          <button
            className="theme-btn form-btn"
            type="submit"
            disabled={!isDirty || !isValid || isSubmitting}
          >
            {isSubmitting && (
              <svg
                className="spinner"
                viewBox="0 0 50 50"
                width="24"
                height="24"
              >
                <circle
                  className="path"
                  cx="25"
                  cy="25"
                  r="20"
                  fill="none"
                  strokeWidth="5"
                ></circle>
              </svg>
            )}
            Send verification code
          </button>
        </div>
      </form>
      {error && (
        <p className={`content-text`}>
          <span className="warning">{error.replace('[GraphQL] ', '')}</span>
        </p>
      )}
      <p className="content-text left">
        <small>*Your number will only be used for secure login purposes.</small>
      </p>
    </>
  )
}

const CodeForm = ({ me, setReloadMe, setSession, session, phone }) => {
  const [error, setError] = useState(null)
  const navigate = useNavigate()

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, isDirty, isValid },
  } = useForm()

  const [userMfaVerifyResult, userMfaVerify] = useMutation(
    buildMutationQuery('userMfaVerify', ['success'])
  )

  useEffect(() => {
    if (userMfaVerifyResult?.error?.message) {
      const errText = userMfaVerifyResult.error.message.replace(
        '[GraphQL] ',
        ''
      )
      setError(errText)
    }
  }, [userMfaVerifyResult?.error?.message])

  const onSubmit = async input => {
    const sanitized = input.code.trim()
    const result = await userMfaVerify({
      input: {
        id: me.id,
        session,
        code: sanitized,
      },
    })

    if (result?.data?.userMfaVerify?.success) {
      navigate('/subscription-success?type=trial')
    }
  }

  return (
    <>
      <p className="content-text">
        Please enter the verification code we sent to {phone}.
      </p>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="input-wrap">
          <input
            className="input"
            type="text"
            placeholder="Verification code"
            onKeyDown={() => setError(null)}
            {...register('code', { required: true })}
          ></input>
        </div>
        <div className="button-wrapper">
          <button
            className="theme-btn form-btn"
            type="submit"
            disabled={!isDirty || !isValid || isSubmitting}
          >
            {isSubmitting && (
              <svg
                className="spinner"
                viewBox="0 0 50 50"
                width="24"
                height="24"
              >
                <circle
                  className="path"
                  cx="25"
                  cy="25"
                  r="20"
                  fill="none"
                  strokeWidth="5"
                ></circle>
              </svg>
            )}
            Verify code
          </button>
          &nbsp;
          <button
            className="theme-btn form-btn"
            type="button"
            onClick={() => setSession(false)}
          >
            Back
          </button>
        </div>
      </form>
      {error && (
        <p className={`content-text`}>
          <span className="warning">{error.replace('[GraphQL] ', '')}</span>
        </p>
      )}
    </>
  )
}

const SmsVerification = () => {
  const { me, setReloadMe } = useStateContext()
  const [phone, setPhone] = useState(null)
  const [session, setSession] = useState(null)

  return (
    <>
      <div className={'sms__container panel'}>
        <div className="sms__panel">
          <h3 className="content-subtitle">Setup two-factor authentication</h3>
          {session ? (
            <CodeForm
              me={me}
              setReloadMe={setReloadMe}
              setSession={setSession}
              session={session}
              phone={phone}
            />
          ) : (
            <PhoneForm me={me} setPhone={setPhone} setSession={setSession} />
          )}
        </div>
      </div>
    </>
  )
}

export default SmsVerification
