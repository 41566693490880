import React, { useEffect, useState } from 'react';
import NavItem from '../NavItem/NavItem';
import NavItemLink from '../NavItem/NavItemLink';
import './Navigation.scss';
import { useLocation } from 'react-router-dom';

const Navigation = () => {
  const [activeLink, setActiveLink] = useState(false); // Initialize activeLink to false

  const location = useLocation();

  useEffect(() => {
    const hasDistribution = location.pathname.includes('/distribution/');
    const hasDistributions = location.pathname.includes('/distributions');
    if (hasDistribution || hasDistributions) {
      setActiveLink(true);
    } else {
      setActiveLink(false);
    }
  }, [location.pathname]);

  return (
    <>
      <nav className="header-nav">
        <ul className="header-nav__list">
          <NavItem href="/billing" name="billing" activeLink={activeLink} />
          <NavItemLink href="https://docs.flashedgecdn.com/" name="documentation" openInNewTab={true} />
          <NavItemLink href="https://www.flashedgecdn.com/contact" name="Contact" openInNewTab={true} />
        </ul>
      </nav>
    </>
  );
};

export default Navigation;
