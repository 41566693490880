import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useMutation } from 'urql'
import { authSignOut, buildMutationQuery } from '../../client'
import { useForm } from 'react-hook-form'

const DeleteAccount = () => {
  const options = [
    'Select a reason for deleting account',
    'Don’t have any use for FlashEdge anymore',
    'Some functions that I need are missing',
    'Too complicated to use',
    'Price is too high',
  ]

  const [selectedItem, setSelectedItem] = useState('')
  const [selectActive, setSelectActive] = useState(false)

  useEffect(() => {
    setSelectedItem('Select a reason for deleting account')
  }, [])

  const [accountDelteResult, accountDelete] = useMutation(
    buildMutationQuery('accountDelete', ['success'])
  )

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { isSubmitting, isDirty, isValid },
  } = useForm()

  const handleSelectClick = () => {
    setSelectActive(!selectActive)
  }
  const handleClick = reason => {
    setSelectedItem(reason)
    setSelectActive(!selectActive)
    if (options.indexOf(reason) === 0) {
      reset({ reason: null })
    } else {
      setValue('reason', reason, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      })
    }
  }

  const checkKeyDown = e => {
    if (e.key === 'Enter') e.preventDefault()
  }

  const onSubmit = async formData => {
    const result = await accountDelete({
      input: {
        reason: selectedItem,
        password: formData.password,
      },
    })

    console.log('Delete Account')

    if (result?.data?.accountDelete?.success) {
      await authSignOut(true)
    }
    /*window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })*/
  }

  return (
    <div className="dashboard__main--content">
      <form
        className="dashboard__main-form"
        onSubmit={handleSubmit(onSubmit)}
        onKeyDown={checkKeyDown}
      >
        <h3 className="content-subtitle">Delete Account</h3>
        <p className="content-text">
          We're really sorry to see you want to delete your FlashEdge account.
          If something was not clear or complex, try to contact our support
          first, so we can try to help you.
        </p>
        <p className="content-text">
          Before proceeding, we would like you to specify the reason for your
          account deletion. We're always looking for ways to improve our
          service.
        </p>
        <div className="input-wrap select-input">
          <div
            className={`input--select-custom ${selectActive ? 'active' : ''}`}
          >
            <span className="input--select-show" onClick={handleSelectClick}>
              {selectedItem}
            </span>
            <input
              className="input"
              type="hidden"
              {...register('reason', { required: true })}
            ></input>
            <div className="input--select-list">
              {options.map((item, index) => {
                return (
                  <span
                    className="input--select-item"
                    key={index}
                    onClick={() => handleClick(item)}
                  >
                    {item}
                  </span>
                )
              })}
            </div>
          </div>
        </div>
        <div className="input-wrap">
          <label className="input-label">
            Fill your password for security reasons
          </label>
          <input
            className="input"
            type="password"
            placeholder="*******"
            {...register('password', { required: true })}
          ></input>
        </div>
        <p className="content-text">
          Closing your account is permanent and can not be reversed. All your
          data will be deleted!!!
        </p>
        {accountDelteResult.error && (
          <p className={`content-text`}>
            <span className="warning">
              {accountDelteResult.error.message.replace('[GraphQL] ', '')}
            </span>
          </p>
        )}
        <button
          className="theme-btn form-btn"
          type="submit"
          disabled={!isDirty || !isValid || isSubmitting}
        >
          {isSubmitting && (
            <svg className="spinner" viewBox="0 0 50 50" width="24" height="24">
              <circle
                className="path"
                cx="25"
                cy="25"
                r="20"
                fill="none"
                strokeWidth="5"
              ></circle>
            </svg>
          )}
          Delete Account
        </button>

        <Link to={`/billing/advanced`}>
          <button className="theme-btn ml-auto-20" type="button">
            Go Back
          </button>
        </Link>
      </form>
    </div>
  )
}

export default DeleteAccount
