import React from 'react'

const ContactUs = () => {
  return (
    <div className='dashboard__main'>
        <div className='dashboard__main--content'>
            Contact Us
        </div>
    </div>
  )
}

export default ContactUs