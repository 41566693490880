import './CreateDist.scss'
import { useMutation } from 'urql'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { buildMutationQuery } from '../../client'
import { SubscriptionGuard } from '../SubscriptionGuard/SubscriptionGuard'
import { useStateContext } from '../../Context/ContextProvider'
import CreatableSelect from 'react-select/creatable'
import { useEffect } from 'react'

const formatStorageOption = s => ({
  label: s.name, /*s.url
    .replace('http://', '')
    .replace('/', '')
    .replace('.s3.amazonaws.com', '')*/
  value: s.awsId + '.s3.amazonaws.com' //s.url.replace('http://', '').replace('/', ''),
})

const CreateDist = () => {
  const navigate = useNavigate()
  const { me, storageList, setReloadDistributionList, setActiveDist } = useStateContext()

  const isTrial = me?.account?.subscription?.type === 'TRIAL'

  const [{ error }, distributionCreate] = useMutation(
    buildMutationQuery('distributionCreate', [
      'success',
      { distribution: ['id'] },
    ])
  )

  const {
    register,
    handleSubmit,
    setValue,
    formState: { isSubmitting, isDirty, isValid },
  } = useForm()

  useEffect(() => {
    register('domainName', { required: true })
    setValue('domainName', null)
  }, [setValue, register])

  const onSubmit = async input => {
    const result = await distributionCreate({
      input: {
        cachePolicy: input.cachePolicy,
        distributionName: input.originName,
        originName: input.originName, // NOTE input.name?
        pricingRegion: input.pricingRegion,
        originDomain: input.domainName,
      },
    })

    if (result?.data?.distributionCreate?.success) {
      setActiveDist(result.data.distributionCreate.distribution.id)
      setReloadDistributionList(true)
      navigate(
        `/distributions/${result.data.distributionCreate.distribution.id}`, { state: { reloadList: true } }
      )
    }
  }

  return (
    <div className="dashboard__main-wrapper">
      <form className="dashboard__main-form" onSubmit={handleSubmit(onSubmit)}>
        <SubscriptionGuard type="form">
          <h2 className="content-title">Create New Distribution</h2>
          <p className="content-text">
            FlashEdge distribution is a globally distributed network of caching
            servers that improve the performance of your site or app hosted on
            any public origin by speeding up the delivery of your content to
            your users.
          </p>
          <h3 className="content-subtitle">Choose Your Origin</h3>
          <p className="content-text-600">
            Your origin can be any server/service with public domain name or for
            example Amazon S3 bucket. Your FlashEdge distribution pulls and
            caches content from the origins that you choose.
          </p>
          <div className="input-wrap">
            <label className="input-label" htmlFor="originName">
              Fill the name of your origin [my-origin-1]
            </label>
            <input
              className="input"
              type="text"
              placeholder="Origin name [my-origin-1]"
              name="originName"
              id="originName"
              required
              {...register('originName', { required: true })}
            ></input>
          </div>
          <div className="input-wrap">
            <label className="input-label" htmlFor="originDomain">
              Fill in the domain name of your origin [s3.website.com,
              virtualmachine.EC2.eu,...]
            </label>
            <CreatableSelect
              isClearable
              placeholder="Origin domain [s3.website.com]"
              className="react-select-container"
              menuPortalTarget={document.body}
              styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
              options={storageList.map(formatStorageOption)}
              onChange={val =>
                setValue('domainName', val?.value, {
                  shouldDirty: true,
                  shouldTouch: true,
                  shouldValidate: true,
                })
              }
              theme={theme => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: '#f1eded',
                  primary: '#e84f1c',
                },
              })}
            />
          </div>
          {/*<h3 className='content-subtitle'>Identify your distribution</h3>
            <p className='content-text'>Your distribution can be configured to cache content from your origin in different ways. Some advanced settings can be set later in the console.</p>
            <div className='input-wrap'>
                <label className='input-label' htmlFor='distributionName'>Select a name for your distribution:</label>
                <input className='input' type="text" placeholder="Enter distribution name..." name='distributionName' id='distributionName' required onChange={handleChange}></input>
            </div>*/}
          <h3 className="content-subtitle">Caching Behaviour</h3>
          <p className="content-text">
            Your distribution can be configured to cache content from your
            origin in different ways.
            <strong>
              {' '}
              Some advanced settings can be set later in the console.
            </strong>{' '}
            Choose a simple pre-configured behavior:
          </p>
          <div className="input-wrap radio-btn">
            <input
              className="input"
              id="best-static"
              name="cachePolicy"
              type="radio"
              value="EVERYTHING"
              required
              {...register('cachePolicy', { required: true })}
            ></input>
            <label className="input-label" htmlFor="best-static">
              <span className="bold-selected">
                Cache everything (Best for static content):
              </span>
              <br />
              Your FlashEdge distribution will cache and serve your entire
              website as static content. Choose this option if your website has
              content that doesn't change depending on who views it, or if your
              website does not use cookies, headers, or query strings to
              personalize content.
            </label>
          </div>
          <div className="input-wrap radio-btn">
            <input
              className="input"
              id="best-dynamic"
              name="cachePolicy"
              type="radio"
              value="NOTHING"
              required
              {...register('cachePolicy', { required: true })}
            ></input>
            <label className="input-label" htmlFor="best-dynamic">
              <span className="bold-selected">
                Cache nothing (Best for dynamic content):
              </span>
              <br />
              Your distribution will not cache any content. Choose this option
              if your website or application uses cookies, headers, and query
              strings to personalize content for individual users.
            </label>
          </div>
          <h3 className="content-subtitle">Pricing Regions</h3>
          <p className="content-text">
            Chose the locations where you want your data to be served.
          </p>
          <div className="input-wrap radio-btn">
            <input
              className="input"
              id="price-region-all"
              name="pricingRegion"
              type="radio"
              value="ALL"
              required
              {...(isTrial
                ? { disabled: true }
                : { ...register('pricingRegion', { required: true }) })}
            ></input>
            <label className="input-label" htmlFor="price-region-all">
              <span className="bold">
                {isTrial ? (
                  <s>Use all FlashEdge locations (best performance) - </s>
                ) : (
                  <span>Use all FlashEdge locations (best performance) - </span>
                )}
              </span>
              $0.03 per GB for NA/EU and $0.06 for rest of the regions
            </label>
          </div>
          <div className="input-wrap radio-btn">
            <input
              className="input"
              id="price-region-na-eu"
              name="pricingRegion"
              type="radio"
              value="NUS_EU"
              required
              {...register('pricingRegion', {
                required: true,
              })}
            ></input>
            <label className="input-label" htmlFor="price-region-na-eu">
              <span className="bold">Use only North America and Europe - </span>
              $0.03 per GB
            </label>
          </div>
          <div className="input-wrap radio-btn">
            <input
              className="input"
              id="price-region-most"
              name="pricingRegion"
              type="radio"
              value="NUS_EU_ASIA_MENA"
              required
              {...(isTrial
                ? { disabled: true }
                : { ...register('pricingRegion', { required: true }) })}
            ></input>

            <label className="input-label" htmlFor="price-region-most">
              <span className="bold">
                {isTrial ? (
                  <s>
                    Use North America, Europe, Asia, Middle East, and Africa -{' '}
                  </s>
                ) : (
                  <span>
                    Use North America, Europe, Asia, Middle East, and Africa -{' '}
                  </span>
                )}
              </span>
              $0.03 per GB for NA/EU and $0.06 for rest of the regions
            </label>
          </div>
          <br />
          <hr />
          <br />
          {/*<h3 className="content-subtitle">Summary</h3>
        <div className="input-wrap">
          <label className="input-label">
            Your Distribution will have this domain name
          </label>
          <input className="input" type="text" readOnly></input>
        </div>*/}
          <p className="content-text">
            Every account gets a free trial for the first 14 days with 500 GB of
            data transfer out of FlashEdge, 20M HTTP/HTTPS requests, 1000 cache
            invalidations and 1 GB of data transfer in (FlashEdge to origin).
          </p>
          <p className="content-text">
            After that:<br></br>
            <label className="input-label">
              <span className="bold">
                $0.03 per GB for NA/EU and $0.06 for rest of the regions{' '}
              </span>
              (Data Transfer Out from FlashEdge to the internet)<br></br>
            </label>
            <label className="input-label">
              <span className="bold">$0.0065 per 10.000 requests </span>
              (HTTP and HTTPS)<br></br>
            </label>
            <label className="input-label">
              <span className="bold">
                $0.02 per GB FlashEdge to origin server{' '}
              </span>
              (Data Transfer Out to Origin)<br></br>
            </label>
            <label className="input-label">
              <span className="bold">$0.005 per invalidation </span>
              (Cache invalidation request)<br></br>
            </label>
          </p>
          {error && (
            <p className={`content-text`}>
              <span className="warning">
                {error.message.replace('[GraphQL] ', '')}
              </span>
            </p>
          )}
          <button
            className="theme-btn form-btn"
            type="submit"
            disabled={!isDirty || !isValid || isSubmitting}
          >
            {isSubmitting && (
              <svg
                className="spinner"
                viewBox="0 0 50 50"
                width="24"
                height="24"
              >
                <circle
                  className="path"
                  cx="25"
                  cy="25"
                  r="20"
                  fill="none"
                  strokeWidth="5"
                ></circle>
              </svg>
            )}
            Create a Distribution
          </button>
        </SubscriptionGuard>
      </form>
    </div>
  )
}

export default CreateDist
