import { gql, useQuery } from 'urql'
import { config } from '../../config'
import dummyData from '../../data'
import { useEffect, useState } from 'react'

// DistributionList
const StorageListQuery = gql`
  query StorageList($limit: Int, $nextToken: String) {
    storageList(limit: $limit, nextToken: $nextToken) {
      items {
        __typename
        id
        awsId
        name
        region
        url
        status
        hasAccess
      }
      nextToken
    }
  }
`

const useStorageList = () => {
  const result = useQuery({
    query: StorageListQuery,
  })
  if (config.local) {
    result[0] = dummyData.storageList
  }
  return result
}

export const useStorageAPI = activeStorage => {
  const [reload, setReload] = useState(false)
  // const [fetching, setFetching] = useState(false)
  const [storageList, setStorageList] = useState([])
  const [currentStorage, setCurrentStorage] = useState({})
  const [listResult, reexecuteQuery] = useStorageList()

  useEffect(() => {
    if (reload) {
      reexecuteQuery({ requestPolicy: 'network-only' })
      setReload(false)
    }
  }, [reexecuteQuery, reload])

  useEffect(() => {
    if (listResult?.data?.storageList?.items) {
      setStorageList(listResult?.data?.storageList?.items)
    }
  }, [listResult?.data?.storageList?.items, setStorageList])

  useEffect(() => {
    if (activeStorage !== currentStorage?.id) {
      setCurrentStorage(listResult?.data?.storageList?.items.find(s => s.id === activeStorage))
    }
  }, [activeStorage, listResult?.data?.storageList?.items])

  return {
    fetching: listResult.fetching,
    reload,
    setReload,
    storageList,
    currentStorage,
    setCurrentStorage,
    reexecuteQuery,
  }
}
