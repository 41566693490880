import './DistHttps.scss'
import { useStateContext } from '../../Context/ContextProvider'
import { useMutation } from 'urql'
import { buildMutationQuery } from '../../client'
import { Link } from 'react-router-dom'
import { SubscriptionGuard } from '../SubscriptionGuard/SubscriptionGuard'

const DistHttps = () => {
  const { activeDist, currentDist, setReloadCurrentDist } = useStateContext()

  const domain = currentDist.domain

  const [domainDeleteResult, domainDelete] = useMutation(
    buildMutationQuery('domainDelete', ['success'])
  )

  const handleDelete = async () => {
    const result = await domainDelete({
      input: {
        id: domain.id,
      },
    })

    if (result?.data?.domainDelete?.success) {
      setReloadCurrentDist(true)
    }
  }

  return (
    <>
      <form className="dashboard__main-form">
        <h3 className="content-subtitle">Use Your Custom Domain with HTTPS</h3>
        <p className="content-text">
          Enabling SSL/TLS adds your registered domain names to your
          distribution. Before you enable TLS/SSL, your distribution accepts
          SSL/TLS traffic for the default domain that is associated with your
          distribution when you first create it (e.g., xxx.flashedge.net).
        </p>
        <p className="content-text">
          When you want to enable SSL/TLS, you must use and verify the FlashEdge
          SSL/TLS certificate that you created for the domains that you want to
          use with your distribution.
        </p>
        {/*
      <div className='input-wrap radio-btn'>
        <input className='input' id="https-disable" name="https-status" type="radio" value="https-disable" defaultChecked></input>
        <label className='input-label' htmlFor="https-disable">
          <span className='bold'>Disable custom domains</span>
        </label>
      </div>
      <div className='input-wrap radio-btn'>
        <input className='input' id="https-enable" name="https-status" type="radio" value="https-enable"></input>
        <label className='input-label' htmlFor="https-enable">
          <span className='bold'>Enable custom domains</span>
        </label>
      </div>
      <div className='input-wrap'>
        <label className='input-label'>Fill Your Primary Domain Name (example.com)</label>
        <input className='input' type="text" placeholder="Example.com"></input>
      </div>
      <div className='input-wrap'>
        <label htmlFor="subdomain" className='input-label'>Fill Your Subdomain Names (www.example.com) Optional</label>
        <PillInput name="subdomain" id="subdomain"/>
      </div>*/}
        <h3 className="content-subtitle">Certificate</h3>
        <p className="content-text">
          You can create SSL/TLS certificates for your domain names.
          Certificates allow you to enable custom domains and HTTPS with your
          distribution.
        </p>
        {/*<div className="input-wrap radio-btn">
          <input
            className="input"
            id="ssl-disable"
            name="ssl-change"
            type="radio"
            value="ssl-disable"
            onChange={handleSSL}
            defaultChecked
          ></input>
          <label className="input-label" htmlFor="ssl-disable">
            <span className="bold">Disable SSL/TLS</span>
          </label>
        </div>
        <div className="input-wrap radio-btn">
          <input
            className="input"
            id="ssl-enable"
            name="ssl-change"
            type="radio"
            value="ssl-enable"
            onChange={handleSSL}
          ></input>
          <label className="input-label" htmlFor="ssl-enable">
            <span className="bold">Enable SSL/TLS</span>
          </label>
        </div>
        <table
          className={`table-wrapper https-table ${sslStatus ? 'active' : ''}`}
        >
        */}
        {domain && (
          <>
            <p className="content-text">
              Please create CNAME record from the information below in your DNS
              or follow our instructions.
            </p>
            <table className="table-wrapper">
              <thead className="table-head">
                <tr className="table-head-row">
                  <th>Domain Name</th>
                  <th>CNAME</th>
                  <th>Value</th>
                  <th>Validation Status</th>
                </tr>
              </thead>
              <tbody className="table-body same-color">
                {domain.records?.map((record, index) => {
                  return (
                    <tr key={index}>
                      <td>{record.name}</td>
                      <td>{record.cname}</td>
                      <td>{record.value}</td>
                      <td>{record.status}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </>
        )}
      </form>
      {domainDeleteResult.error && (
        <p className={`content-text`}>
          <span className="warning">
            {domainDeleteResult.error.message.replace('[GraphQL] ', '')}
          </span>
        </p>
      )}
      <div className="button-wrapper">
        <SubscriptionGuard>
          <Link to={`/distributions/${activeDist}/https/create`}>
            <button className="theme-btn" type="button" disabled={!!domain}>
              Create Cerfiticate
            </button>
          </Link>
          <button
            className="theme-btn form-btn inverted"
            type="button"
            onClick={handleDelete}
            disabled={domainDeleteResult.fetching || !domain}
          >
            {domainDeleteResult.fetching && (
              <svg
                className="spinner"
                viewBox="0 0 50 50"
                width="24"
                height="24"
              >
                <circle
                  className="path"
                  cx="25"
                  cy="25"
                  r="20"
                  fill="none"
                  strokeWidth="5"
                ></circle>
              </svg>
            )}
            Delete
          </button>
        </SubscriptionGuard>
        <a
          href="https://docs.flashedgecdn.com/flashedge-distribution-tutorials/setting-up-https-with-a-custom-domain"
          className="theme-btn ml-auto"
          type="button"
        >
          Instructions
        </a>
      </div>
    </>
  )
}

export default DistHttps
