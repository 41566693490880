import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import './Assets/Styles/global.scss'
import { ContextProvider } from './Context/ContextProvider'
import { config } from './config'
import { AuthProvider } from './Context/AuthContext'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <AuthProvider value={config}>
      <ContextProvider>
        <App />
      </ContextProvider>
    </AuthProvider>
  </React.StrictMode>
)
