import React from 'react'
import './Logo.scss'
import myLogo from '../../Assets/Images/fe-logo.svg';

const Logo = () => {
  return (
    <div className='header-logo-wrapper'>
      <object className='header-logo-image' data={myLogo} type="image/svg+xml">FlashEdge Logo</object>
    </div>
  )
}

export default Logo