import React from 'react'
import { Outlet } from 'react-router-dom'
import MainNavDist from '../MainNavDist/MainNavDist'

const Distribution = () => {
  return (
    <>
      <MainNavDist />
      <Outlet />
    </>
  )
}

export default Distribution
