import './Main.scss'
import { Outlet } from 'react-router-dom'

const Main = () => {
  return (
    <>
      <div className="dashboard__main">
        <Outlet />
      </div>
    </>
  )
}

export default Main
