// import './Distributions.scss'
import orangePlus from '../../Assets/Images/orange-plus.svg'
import distIcon from '../../Assets/Images/distribution-icon.svg'
import { useStateContext } from '../../Context/ContextProvider'
import { Link, useLocation } from 'react-router-dom'
import { SubscriptionGuard } from '../SubscriptionGuard/SubscriptionGuard'
import { useStorageAPI } from '../../Context/ContextProvider/StorageApi'
import { useEffect } from 'react'

const StorageList = ({ limit }) => {
  const location = useLocation()
  const { storageList, me, setReloadStorage } = useStateContext()

  useEffect(() => {
    if (location.state?.reload) {
      delete location.state.reload
      setReloadStorage(true)
    }
  }, [location.state?.reload])

  return (
    <div className="dashboard__main--distributions">
      <h2 className="dashboard__main--distributions-title">Storage</h2>
      {/*queryResult.error && <p>Oh no... {queryResult.error.message}</p>}
      {queryResult.fetching && <p>Loading...</p>*/}
      <ul className="dashboard__main--distributions-list">
        <SubscriptionGuard>
          <li className="dashboard__main--distributions-list-item create-distribution">
            <Link to="/storage/create">
              <img
                className="dashboard__main--distributions-list-item--icon"
                src={orangePlus}
                alt="Orange Plus"
              ></img>
              <h3 className="dashboard__main--distributions-list-item-title">
                Create Storage
              </h3>
            </Link>
          </li>
        </SubscriptionGuard>
        {storageList && (
          <>
            {storageList.map((item, index) => {
              return (
                <li
                  key={index}
                  className="dashboard__main--distributions-list-item"
                >
                  <Link to={`/storage/${item.id}`}>
                    <div className="dashboard__main--distributions-list-item-header">
                      <h3 className="dashboard__main--distributions-list-item-title">
                        {item.region.substring(0, 1) +
                          item.region.substring(1).toLowerCase()}
                      </h3>
                      <div className="dashboard__main--distributions-list-item-status">
                        <span
                          className={`item-status ${
                            item.status ? item.status.toLowerCase() : ''
                          }`}
                        ></span>
                        <p className="status-text"></p>
                      </div>
                    </div>
                    <div className="dashboard__main--distributions-list-item--dist-icon">
                      <img src={distIcon} alt="Distribution Icon"></img>
                    </div>
                    <div className="dashboard__main--distributions-list-item--info">
                      <h4 className="dashboard__main--distributions-list-item--info-title">
                        Current Spending:{' '}
                        {me?.account?.subscription?.type !== 'TRIAL'
                          ? `${item.usage?.totalPrice ?? '0'} USD`
                          : '-'}
                      </h4>
                      <span className="dashboard__main--distributions-list-item--info-content">
                        {item.currentSpending}
                      </span>
                    </div>
                    <div className="dashboard__main--distributions-list-item--info">
                      {/*<h4 className="dashboard__main--distributions-list-item--info-title">
                        {item.name}
                        </h4>*/}
                      <span className="dashboard__main--distributions-list-item--info-content">
                        {item.name}
                      </span>
                    </div>
                  </Link>
                </li>
              )
            })}
          </>
        )}
      </ul>
    </div>
  )
}

export default StorageList
