import React, { useState } from 'react'
import { useStateContext } from '../../Context/ContextProvider'
import { useStorageAPI } from '../../Context/ContextProvider/StorageApi'
import { Link } from 'react-router-dom'
// import './DistMonitoring.scss'

const StorageAdvanced = () => {
  const {
    me,
    activeStorage,
    isSubscription,
  } = useStateContext()
  const { setReload, currentStorage } = useStorageAPI(activeStorage)

  return (
    <>
      <h3 className="content-subtitle">Delete {currentStorage?.name} storage</h3>
      <p className="content-text font-size-18">
        Deleting your FlashEdge storage will permanently delete it. Update the
        origin of your distribution before deleting your storage to avoid
        traffic interruption. Before deleting your storage, please back up your data and manually delete all data so that the storage does not contain any files.
      </p>
      
      <Link to={`/storage/${activeStorage}/advanced/delete`}>
        <button className="theme-btn" type="button">
          Delete
        </button>
      </Link>
    </>
  )
}

export default StorageAdvanced
