import { useMutation } from 'urql'
import { buildMutationQuery } from '../../client'
import PageSpinner from '../PageSpinner/PageSpinner'
import { useEffect, useState } from 'react'

export const useSubscriptionCheckout = () => {
  const [fetching, setFetching] = useState(false)
  const [subscriptionCheckoutResult, executeSubscriptionCheckout] = useMutation(
    buildMutationQuery('subscriptionCheckout', ['redirectUrl'])
  )

  const openSubscriptionCheckout = async e => {
    if (e) e.preventDefault()

    setFetching(true)
    const result = await executeSubscriptionCheckout({
      input: {
        successUrl: window.location.origin + `/subscription-success`,
        // cancelUrl: window.location.href + `?success=0`,
        cancelUrl: window.location.origin + `/billing?success=0`,
      },
    })

    if (result.data?.subscriptionCheckout?.redirectUrl) {
      // window.open(result.data?.subscriptionPortal?.redirectUrl, '_blank')
      setFetching(false)
      window.location.href = result.data?.subscriptionCheckout?.redirectUrl
    }
  }

  return { subscriptionCheckoutResult, openSubscriptionCheckout, fetching }
}

export const SubscriptionCheckoutRedirect = () => {
  const { openSubscriptionCheckout, fetching } = useSubscriptionCheckout()

  useEffect(() => {
    if (!fetching && openSubscriptionCheckout) {
      openSubscriptionCheckout()
    }
  }, [])
  return (
    <PageSpinner type="panel">
      <h3 className="success-title">Redirecting to the checkout page.</h3>
      <span style={{ color: '#676767' }}>
        You will be redirected in a moment.
      </span>
    </PageSpinner>
  )
}

export const SubscriptionCheckoutLink = ({ children }) => {
  return <a href="/subscription-checkout">{children}</a>
}
