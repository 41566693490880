import { SubscriptionCheckoutLink } from './SubscriptionCheckoutLink'
import { SubscriptionEndTrial } from './SubscriptionEndTrialLink'
import { SubscriptionPortalLink } from './SubscriptionPortalLink'

const datePart = (dateString, part) => {
  const result = new Date(dateString).toLocaleString('cz-CZ')
  if (!part) {
    return result
  }
  const parts = result.split(', ')
  switch (part) {
    case 'date':
      return parts[0]
    case 'time':
      return parts[1]
    default:
      return result
  }
}

const DateFormat = ({ date, part }) => <strong>{datePart(date, part)}</strong>

/*const PeriodEnds = ({ date }) => (
  <strong>{new Date(date).toLocaleString('cz-CZ')}</strong>
)*/

export const SubscriptionInfo = ({ subscription }) => {
  const hasPaymentMethod = subscription.paymentMethod

  if (subscription.type === 'TRIAL') {
    return subscription.status === 'ACTIVE' ? (
      <>
        Your trial period ends on <DateFormat date={subscription.periodEnd} />.{' '}
        {!hasPaymentMethod ? (
          <>
            Please add{' '}
            <SubscriptionPortalLink>your payment method</SubscriptionPortalLink>{' '}
            to continue using your services after trial.
          </>
        ) : (
          <>
            Upgrade <SubscriptionEndTrial>here</SubscriptionEndTrial> to a paid
            plan with no limits, or manage your subscription in the{' '}
            <SubscriptionPortalLink>billing portal</SubscriptionPortalLink>.
          </>
        )}
      </>
    ) : (
      <>
        Your trial period ended on <DateFormat date={subscription.endedAt} />.{' '}
        You can sign up for a paid plan&nbsp;
        <SubscriptionCheckoutLink>here</SubscriptionCheckoutLink>.
      </>
    )
  } else if (subscription.type === 'PLAN') {
    return subscription.status === 'ACTIVE' ? (
      <>
        Your plan renews on <DateFormat date={subscription.billingAt} />. Manage
        your subscription in the{' '}
        <SubscriptionPortalLink>billing portal</SubscriptionPortalLink>.
      </>
    ) : (
      <>
        Your plan was {subscription.status.toLowerCase()} on{' '}
        <DateFormat date={subscription.endedAt} />{' '}
        {new Date(subscription.periodEnd) > new Date(subscription.endedAt)
          ? `(with billing on ${datePart(subscription.periodEnd, 'date')})`
          : ''}
        . You can resubscribe for a paid plan{' '}
        <SubscriptionCheckoutLink>here</SubscriptionCheckoutLink>.
      </>
    )
  } else {
    return <>No subscription info. Please contact support.</>
  }
}
