import React from 'react'
import { useStateContext } from '../../Context/ContextProvider'
import { useMutation } from 'urql'
import { buildMutationQuery } from '../../client'
import { Link, useNavigate } from 'react-router-dom'

const DeleteAdvanced = () => {
  const { setReloadCurrentDist, activeDist } = useStateContext()
  const navigate = useNavigate()

  const [distributionDeleteResult, distributionDelete] = useMutation(
    buildMutationQuery('distributionDelete', ['success'])
  )

  const handleDelete = async () => {
    const result = await distributionDelete({
      input: {
        id: activeDist,
      },
    })

    if (result?.data?.distributionDelete?.success) {
      setReloadCurrentDist(true)
      navigate('/distributions')
    }
  }

  return (
    <form className="dashboard__main-form">
      <h3 className="content-subtitle">Delete FlashEdge Distribution</h3>
      <p className="content-text">
        Deleting your FlashEdge distribution will permanently delete it. Update
        the DNS records of your domain before deleting your distribution to
        avoid traffic interruption to your origin.
      </p>
      <p className="content-text">
        <span className="warning">Warning:</span> This process will completely
        remove this distribution and everything associated with it(certificates,
        settings).
      </p>
      <p className="content-text content-bold">
        Are you sure you want to delete this distribution?
      </p>
      <button
        className="theme-btn form-btn"
        type="button"
        onClick={handleDelete}
      >
        {distributionDeleteResult.fetching && (
          <svg className="spinner" viewBox="0 0 50 50" width="24" height="24">
            <circle
              className="path"
              cx="25"
              cy="25"
              r="20"
              fill="none"
              strokeWidth="5"
            ></circle>
          </svg>
        )}
        Delete
      </button>
      <Link to={`/distributions/${activeDist}/advanced`}>
        <button className="theme-btn ml-auto-20" type="button">
          Go Back
        </button>
      </Link>
    </form>
  )
}

export default DeleteAdvanced
