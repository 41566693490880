import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Auth } from '@aws-amplify/auth'

const ChangePass = () => {
  const [errorMessage, setErrorMessage] = useState()

  console.log(errorMessage, setErrorMessage)
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, isDirty, isValid },
  } = useForm()

  const changePassword = async (oldPassword, newPassword) => {
    try {
      const user = await Auth.currentAuthenticatedUser()
      const data = await Auth.changePassword(user, oldPassword, newPassword)
      console.log(data)
      setErrorMessage()
    } catch (err) {
      console.log(err)
      setErrorMessage(err.message)
    }
  }

  const onSubmit = async input => {
    const { currentPassword, newPassword, newPasswordAgain } = input
    console.log(input)
    if (newPassword !== newPasswordAgain) {
      setErrorMessage('New passwords do not match.')
    } else {
      await changePassword(currentPassword, newPassword)
    }
  }

  return (
    <form className="dashboard__main-form" onSubmit={handleSubmit(onSubmit)}>
      <div className="dashboard__main--content">
        <h3 className="content-subtitle">Change Password</h3>
        <p className="content-text"></p>
        <div className="input-wrap">
          <label className="input-label">Current Password</label>
          <input
            className="input"
            type="password"
            placeholder="Fill Your Current Password"
            required
            {...register('currentPassword', { required: true })}
          ></input>
        </div>
        <div className="input-wrap">
          <label className="input-label">New Password</label>
          <input
            className="input"
            type="password"
            placeholder="Fill Your New Password"
            required
            {...register('newPassword', { required: true })}
          ></input>
        </div>
        <div className="input-wrap">
          <label className="input-label">Type New Password Again</label>
          <input
            className="input"
            type="password"
            placeholder="Fill New Password Again"
            required
            {...register('newPasswordAgain', { required: true })}
          ></input>
        </div>
        {errorMessage && (
          <p className={`content-text`}>
            <span className="warning">{errorMessage}</span>
          </p>
        )}
        <div className="button-wrapper">
          <button
            className="theme-btn form-btn"
            type="submit"
            disabled={!isDirty || !isValid || isSubmitting}
          >
            {isSubmitting && (
              <svg
                className="spinner"
                viewBox="0 0 50 50"
                width="24"
                height="24"
              >
                <circle
                  className="path"
                  cx="25"
                  cy="25"
                  r="20"
                  fill="none"
                  strokeWidth="5"
                ></circle>
              </svg>
            )}
            Change password
          </button>
        </div>
      </div>
    </form>
  )
}

export default ChangePass
