import { NavLink } from 'react-router-dom'
import './NavItem.scss'

const NavItem = ({ href, name, activeLink }) => {
  return (
    <>
      <li className="header-nav__list-item">
        <NavLink
          to={href}
          className={`header-nav__list-item-link ${({ isActive }) =>
            isActive ? 'active' : null} ${
            href === '/' && activeLink ? 'active' : null
          }`}
        >
          {name}
        </NavLink>
      </li>
    </>
  )
}
export default NavItem
