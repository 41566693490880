import { useMutation } from 'urql'
import { useForm } from 'react-hook-form'
import { buildMutationQuery } from '../../client'
import { useStateContext } from '../../Context/ContextProvider'
import CountryList from './countryList.json'
import { useEffect, useMemo, useState } from 'react'

const formValues = account => {
  // Clone
  const values = account ? JSON.parse(JSON.stringify(account)) : undefined
  // Delete some props
  delete values?.subscription
  delete values?.status
  delete values?.__typename
  return values
}

export const BillingForm = () => {
  const { me } = useStateContext()

  const [selectedItem, setSelectedItem] = useState('')
  const [selectActive, setSelectActive] = useState(false)

  // const account = me?.account ? formValues(me?.account) : {}
  const account = useMemo(() => formValues(me?.account), [me?.account])

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting, isDirty, isValid },
  } = useForm({
    values: account,
  })

  /*useEffect(() => {
    reset(me?.account)
  }, [me?.account])*/

  useEffect(() => {
    if (account) {
      const selectedItem = CountryList.find(
        cl => cl.value === account.countryCode
      )
      if (selectedItem) {
        setSelectedItem(selectedItem)
      }
    }
  }, [account, account?.countryCode])

  const handleSelectClick = () => {
    setSelectActive(!selectActive)
  }
  const handleClick = country => {
    setValue('countryCode', country.value, { shouldDirty: true })
    setSelectedItem(country)
    setSelectActive(!selectActive)
  }

  const [accountUpdateResult, accountUpdate] = useMutation(
    buildMutationQuery('accountUpdate', ['success'])
  )

  const onSubmit = async input => {
    delete input.__typename
    const result = await accountUpdate({
      input,
    })

    if (result.data.success) {
      console.log('Success')
    }
  }

  /*if (meResult.fetching) {
    return 'Loading...'
  } else if (meResult.error) {
    return 'There was an error :('
  }*/

  return (
    <form className="billing__form" onSubmit={handleSubmit(onSubmit)}>
      {/*meResult.error && <p>Oh no... {meResult.error.message}</p>*/}
      {/*meResult.fetching && <p>Loading...</p>*/}
      <div className="input-col-2">
        <div className="input-wrap">
          <label className="input-label">First Name</label>
          <input
            className="input"
            type="text"
            placeholder="First Name"
            required
            {...register('firstName', { required: true })}
          />
          {errors.firstName && <p>This field is required</p>}
        </div>
        <div className="input-wrap">
          <label className="input-label">Last Name</label>
          <input
            className="input"
            type="text"
            placeholder="Last Name"
            required
            {...register('lastName', { required: true })}
          />
          {errors.lastName && <p>This field is required</p>}
        </div>
      </div>
      <div className="input-wrap">
        <label className="input-label">Company Name</label>
        <input
          className="input"
          type="text"
          placeholder="Company Name"
          {...register('companyName', { required: false })}
        />
        {errors.companyName && <p>This field is required</p>}
      </div>
      <div className="input-col-2">
        <div className="input-wrap">
          <label className="input-label">Street Address</label>
          <input
            className="input"
            type="text"
            placeholder="Street Address"
            required
            {...register('street', { required: true })}
          />
          {errors.street && <p>This field is required</p>}
        </div>
        <div className="input-wrap" style={{ zIndex: 50 }}>
          <label className="input-label">Country</label>

          <div
            className={`input--select-custom ${selectActive ? 'active' : ''}`}
          >
            <span className="input--select-show" onClick={handleSelectClick}>
              {selectedItem?.label || 'Country'}
            </span>
            <div
              className="input--select-list"
              style={{ maxHeight: '350px', overflow: 'auto', zIndex: 100000 }}
            >
              {CountryList &&
                CountryList.map((country, index) => {
                  return (
                    <span
                      className="input--select-item"
                      key={index}
                      onClick={() => handleClick(country)}
                    >
                      {country.label}
                    </span>
                  )
                })}
            </div>
          </div>

          {/*<select
            options={CountryList}
            className="input"
            {...register('countryCode', { required: true })}
          />
          <input
            className="input"
            type="text"
            placeholder="Country"
            required
            {...register('countryCode', { required: true })}
          />*/}
          {errors.countryCode && <p>This field is required</p>}
        </div>
      </div>
      <div className="input-col-2">
        <div className="input-wrap">
          <label className="input-label">City</label>
          <input
            className="input"
            type="text"
            placeholder="City"
            required
            {...register('city', { required: true })}
          />
          {errors.city && <p>This field is required</p>}
        </div>
        <div className="input-wrap">
          <label className="input-label">Zip Code</label>
          <input
            className="input"
            type="text"
            placeholder="Zip Code"
            required
            {...register('zip', { required: true })}
          />
          {errors.zip && <p>This field is required</p>}
        </div>
      </div>
      <div className="input-col-2">
        <div className="input-wrap">
          <label className="input-label">
            VAT Number (for EU business customers)
          </label>
          <input
            className="input"
            type="text"
            placeholder="VAT ID"
            {...register('vatId', { required: false })}
          />
        </div>
        <div className="input-wrap"></div>
      </div>
      <button
        className="theme-btn form-btn"
        type="submit"
        disabled={!isDirty || !isValid || isSubmitting}
      >
        {isSubmitting && (
          <svg className="spinner" viewBox="0 0 50 50" width="24" height="24">
            <circle
              className="path"
              cx="25"
              cy="25"
              r="20"
              fill="none"
              strokeWidth="5"
            ></circle>
          </svg>
        )}
        Update Details
      </button>
    </form>
  )
}
