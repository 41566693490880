import React from 'react'
import { gql, useQuery } from 'urql'

const InvoiceListQuery = gql`
  query InvoiceList {
    invoiceList(limit: 60) {
      nextToken
      items {
        id
        number
        periodStart
        periodEnd
        date
        total
        paid
        currencyCode
        pdfUrl
        hostedUrl
        status
      }
    }
  }
`

const BillingInvoices = () => {
  const [invoiceListResult] = useQuery({
    query: InvoiceListQuery,
  })

  const invoices = invoiceListResult.data?.invoiceList?.items

  return (
    <div className="dashboard__main--content">
      {invoiceListResult.error && (
        <p>
          Oh no... {invoiceListResult.error.message.replace('[GraphQL] ', '')}
        </p>
      )}
      <table className="table-wrapper">
        <thead className="table-head">
          <tr className="table-head-row">
            <th>Date</th>
            <th>Invoice Number</th>
            <th>Final Amount</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody className="table-body">
          {invoiceListResult.fetching ? (
            <tr>
              <td colSpan="4">Loading...</td>
            </tr>
          ) : !invoices || invoices.length === 0 ? (
            <tr>
              <td colSpan="4">No invoice yet</td>
            </tr>
          ) : (
            invoices.map((invoice, index) => {
              return (
                <tr key={index}>
                  <td>{new Date(invoice.date).toLocaleDateString('cz-CZ')}</td>
                  <td>{invoice.number}</td>
                  <td>
                    {invoice.total} {invoice.currencyCode}
                  </td>
                  <td style={{ paddingLeft: '0px', paddingRight: '10px' }}>
                    <a
                      href={invoice.hostedUrl}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <button className="theme-btn-table" type="button">
                        Open
                      </button>
                    </a>
                    <a href={invoice.pdfUrl}>
                      <button
                        className="theme-btn-table inverted"
                        type="button"
                      >
                        Download
                      </button>
                    </a>
                  </td>
                </tr>
              )
            })
          )}
        </tbody>
      </table>
    </div>
  )
}

export default BillingInvoices
