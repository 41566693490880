import { useEffect, useState } from 'react'
import { Pie } from 'react-chartjs-2'

import './PieChart.scss'

function PieChart({ DataPie, type }) {
  const [remaining, setRemaining] = useState(0)
  const [pieTotal, setPieTotal] = useState(0)
  const [chartData, setChartData] = useState({
    datasets: [
      {
        data: DataPie.map(data => data.quantity),
        label: type,
        backgroundColor: ['#FABA00', '#FEDB75'],
        borderWidth: 0,
      },
    ],
    labels: DataPie.map(data => data.name),
  })

  const testFunc = () => {
    setChartData()
  }

  useEffect(() => {
    setPieTotal(DataPie[0].quantity + DataPie[1].quantity)
  }, [DataPie])
  useEffect(() => {
    setRemaining(pieTotal - DataPie[0].quantity)
  }, [pieTotal, DataPie])

  return (
    <>
      <span onClick={testFunc} style={{ display: 'none' }}></span>
      <div className="pie-chart-container">
        <span></span>
        <Pie
          data={chartData}
          options={{
            plugins: {
              legend: {
                display: false,
              },
            },
          }}
        />
      </div>
      <span className="pie-chart-indicator">
        {remaining} / {pieTotal}
      </span>
    </>
  )
}
export default PieChart
