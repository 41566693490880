// import './MainNavDist.scss'

import { useStateContext } from '../../Context/ContextProvider'
import { NavLink } from 'react-router-dom'

const MainNavStorage = () => {
  const { activeStorage } = useStateContext()

  return (
    <div className="dashboard__main--navigation">
      <ul className="dashboard__main--navigation-list">
        <li>
          <NavLink
            to={`/storage/${activeStorage}`}
            end
            className="dashboard__main--navigation-list-item"
          >
            Overview
          </NavLink>
        </li>
        {/*<li>
          <NavLink
            to={`/storage/${activeStorage}/monitoring`}
            className="dashboard__main--navigation-list-item"
          >
            Monitoring
          </NavLink>
        </li>*/}
        <li>
          <NavLink
            to={`/storage/${activeStorage}/remote-access`}
            className="dashboard__main--navigation-list-item"
          >
            Remote access
          </NavLink>
        </li>
        <li>
          <NavLink
            to={`/storage/${activeStorage}/advanced`}
            className="dashboard__main--navigation-list-item"
          >
            Advanced
          </NavLink>
        </li>
      </ul>
    </div>
  )
}

export default MainNavStorage
