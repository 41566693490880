import './TrialBar.scss'
import { useStateContext } from '../../Context/ContextProvider'
import { SubscriptionInfo } from '../SubscriptionInfo/SubscriptionInfo'

const TrialBar = () => {
  const { me, spinner } = useStateContext()

  const subscription = me?.account?.subscription

  return (
    !spinner &&
    subscription?.type === 'TRIAL' && (
      <div className="trial-bar">
        <SubscriptionInfo subscription={subscription} />
      </div>
    )
  )
}

export default TrialBar
