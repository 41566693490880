import React from 'react'
import Header from '../Header/Header'
import BottomBar from '../BottomNav/BottomBar'
import { Outlet } from 'react-router-dom'
import TrialBar from '../TrialBar/TrialBar'

const Home = () => {
  return (
    <>
      <TrialBar />
      <Header />
      <Outlet />
      <BottomBar />
    </>
  )
}

export default Home
