import { Outlet, useParams } from 'react-router-dom'
import { routeHandler } from '../../Context/RouteHandler'
import { useEffect } from 'react'
import { useStateContext } from '../../Context/ContextProvider'

const Layout = () => {
  const context = useStateContext()
  const params = useParams()

  useEffect(() => {
    if (params) {
      routeHandler(context, params)
    }
  }, [context, params])

  return <Outlet />
}

export default Layout
