import { useStateContext } from '../../Context/ContextProvider'
import { useMutation } from 'urql'
import { buildMutationQuery } from '../../client'
import { Link } from 'react-router-dom'
import { SubscriptionGuard } from '../SubscriptionGuard/SubscriptionGuard'

const DistOrigins = ({ limit }) => {
  const {
    activeDist,
    currentDist,
    currentDistFetching,
    isSubscription,
    setReloadCurrentDist,
  } = useStateContext()

  const [originDeleteResult, originDelete] = useMutation(
    buildMutationQuery('originDelete', ['success'])
  )

  const checkCacheBinding = originId => {
    const caches = currentDist?.caches

    for (const cache of caches) {
      if (cache.originId === originId) {
        return true
      }
    }
    return false
  }

  const handleDelete = async id => {
    if (checkCacheBinding(id)) {
      return alert(
        'This origin is used by one of the cache behaviors. First delete the cache behavior or change its origin.'
      )
    }

    const result = await originDelete({
      input: {
        id,
      },
    })

    if (result?.data?.originDelete?.success) {
      setReloadCurrentDist(true)
    }
  }

  const origins = currentDist?.origins

  return (
    <form className="dashboard__main-form">
      <h3 className="content-subtitle">Origins</h3>
      <p className="content-text">
        An origin refers to the storage location of content, from which
        FlashEdge ditribution retrieves and serves the content to viewers.
      </p>
      <p className="content-text">
        Here you can create multiple origins or just use one and set some basic
        settings that for instance selects which type of protocol (HTTP or
        HTTPS) to use for the communication with your origin.
      </p>
      <table className="table-wrapper">
        <thead className="table-head">
          <tr className="table-head-row">
            <th>Origin Name</th>
            <th>Origin Domain</th>
            <th>Path</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody className="table-body">
          {/*queryResult.error && (
            <tr>
              <td colSpan="4">Oh no... {queryResult.error.message}</td>
            </tr>
          )*/}
          {currentDistFetching ? (
            <tr>
              <td colSpan="4">Loading...</td>
            </tr>
          ) : (
            origins &&
            origins.map((origin, index) => {
              return (
                <tr key={index}>
                  <td>{origin.name}</td>
                  <td>{origin.domainName}</td>
                  <td>{origin.path}</td>
                  <td>
                    <SubscriptionGuard>
                      <Link
                        to={`/distributions/${activeDist}/origins/${origin.id}/edit`}
                      >
                        <button
                          className="theme-btn-table"
                          type="button"
                          disabled={!isSubscription}
                        >
                          Edit
                        </button>
                      </Link>
                      <button
                        className="theme-btn-table inverted"
                        type="button"
                        onClick={() => handleDelete(origin.id)}
                      >
                        Delete
                      </button>
                    </SubscriptionGuard>
                  </td>
                </tr>
              )
            })
          )}
        </tbody>
      </table>
      <SubscriptionGuard>
        <Link to={`/distributions/${activeDist}/origins/create`}>
          <button className="theme-btn" type="button">
            Create New Origin
          </button>
        </Link>
      </SubscriptionGuard>
    </form>
  )
}

export default DistOrigins
