import {
  Client,
  fetchExchange,
  cacheExchange,
  subscriptionExchange,
} from 'urql'
import { authExchange } from '@urql/exchange-auth'
import { Auth } from '@aws-amplify/auth'
import { SubscriptionClient } from 'subscriptions-transport-ws'
import { print as graphqlPrint } from 'graphql/language/printer'
import { v4 } from 'uuid'
import { config } from './config'
import dummyData from './data.json'

export const omit = (obj, fields) => {
  fields.forEach(f => {
    delete obj[f]
  })
  return obj
}

const capitalizeFirstLetter = string =>
  string.charAt(0).toUpperCase() + string.slice(1)

export const buildFields = (fields, idx = 3, rep = `  `) =>
  fields
    .map((f, i) =>
      typeof f === 'object'
        ? Object.entries(f).map(
            ([fn, fv]) =>
              `${`\n` + rep.repeat(idx)}${fn}{${buildFields(fv, idx + 1)}${
                `\n` + rep.repeat(idx)
              }}`
          )
        : (i > 0 ? `\n` + rep.repeat(idx) : ``) + f
    )
    .join(``)

export const buildQueryById = (name, fields) => `
  query ${capitalizeFirstLetter(name)}($id: ID!) {
    ${name}(id: $id) {
      ${buildFields(fields, 3)}
    }
  }
`

export const buildMutationQuery = (name, fields = ['success']) => `
  mutation ${capitalizeFirstLetter(name)}($input: ${capitalizeFirstLetter(
  name
)}Input!) {
    ${name}(input: $input) {
      ${buildFields(fields, 3)}
    }
  }
`

class AppSyncSubscriptionClient extends SubscriptionClient {
  ack = false
  processReceivedData(receivedData) {
    let parsedMessage
    // let opId
    try {
      parsedMessage = JSON.parse(receivedData)
      // opId = parsedMessage.id
    } catch (e) {
      throw new Error(`Message must be JSON-parseable. Got: ${receivedData}`)
    }

    if (parsedMessage.type === 'start_ack') {
      return
    }

    super.processReceivedData(receivedData)
  }
  generateOperationId() {
    return v4()
  }
}

export const authConfigure = async awsConfig => {
  if (!config.local) {
    Auth.configure(awsConfig.Auth)
  }
}

export const authSignedIn = async (redirect = false) => {
  try {
    await authSession(redirect)
  } catch (err) {
    return false
  }
}

export const authSession = async (redirect = false) => {
  if (config.local) {
    return dummyData.session
  }
  try {
    const session = await Auth.currentSession()
    return session
  } catch (err) {
    if (err === 'No current user' && redirect) {
      window.location.href = process.env.REACT_APP_WEB_URL + '/login'
    }
    return null
  }
}

export const authSignOut = async (redirect = false) => {
  await Auth.signOut()
  if (redirect) {
    window.location.href = process.env.REACT_APP_WEB_URL + '/login'
  }
}

const appsyncOperationMiddleware = () => ({
  applyMiddleware: async (options, next) => {
    // AppSync expects GraphQL operation to be defined as a JSON-encoded object in a "data" property
    options.data = JSON.stringify({
      query:
        typeof options.query === 'string'
          ? options.query
          : graphqlPrint(options.query),
      variables: options.variables,
    })

    const session = await authSession()
    // AppSync only permits authorized operations
    const a = document.createElement('a')
    a.href = process.env.REACT_APP_APPSYNC_ENDPOINT
    options.extensions = {
      authorization: {
        host: a.hostname,
        authorization: session.getIdToken().getJwtToken(),
      },
    }

    // AppSync does not care about these properties
    delete options.operationName
    delete options.variables
    // Not deleting "query" property as SubscriptionClient validation requires it

    next()
  },
})

const appSyncSubscriptionExchange = (url, token) => {
  const a = document.createElement('a')
  a.href = process.env.REACT_APP_APPSYNC_ENDPOINT

  const headers = {
    host: a.hostname,
    authorization: token,
  }
  let header = btoa(JSON.stringify(headers))

  const subscriptionClient = new AppSyncSubscriptionClient(
    `${url}?header=${header}&payload=e30=`,
    {
      timeout: 300000,
      lazy: true,
      reconnect: true,
      reconnectionAttempts: 5,

      connectionParams: async () => {
        return {
          headers,
        }
      },
      connectionCallback: err => {
        subscriptionClient.ack = true
        if (err) {
          console.error(err)
          subscriptionClient.close(false, false)
        }
      },
    }
  )
  subscriptionClient.onDisconnected(() => (subscriptionClient.ack = false))
  subscriptionClient.use([
    appsyncOperationMiddleware(),
    {
      applyMiddleware(options, next) {
        /*if (!subscriptionClient.ack) {
          throw new Error('not ready')
        }*/
        const i = setInterval(() => {
          if (subscriptionClient.ack) {
            clearInterval(i)
            next()
          }
        }, 1000)
      },
    },
  ])

  return subscriptionExchange({
    forwardSubscription: request => subscriptionClient.request(request),
  })
  /* {
      return subscriptionClient.request({
        ...request,
        data: JSON.stringify({
          query: request.query,
          variables: request.variables,
        }),
        extensions: {
          authorization: headers,
        },
      })
    },
  }) */
}

const cognitoAuthExchange = authExchange(async utils => {
  let session = await authSession(true)
  return {
    addAuthToOperation(operation) {
      const token = session.getIdToken().getJwtToken()
      if (!token) {
        return operation
      }
      return utils.appendHeaders(operation, {
        Authorization: `Bearer ${token}`,
      })
    },
    willAuthError() {
      return config.local || !session.isValid()
    },
    didAuthError(error) {
      return error.graphQLErrors.some(e => e.message === 'Unauthorized')
    },
    async refreshAuth() {
      if (!config.local) {
        session = await authSession()
      }
    },
  }
})

export const initGraphQLClient = ({
  graphqlEndpoint,
  subscriptionEndpoint,
  token,
}) =>
  new Client({
    url: graphqlEndpoint,
    exchanges: [
      cognitoAuthExchange,
      appSyncSubscriptionExchange(subscriptionEndpoint, token),
      cacheExchange,
      fetchExchange,
    ],
    /*fetchOptions: async () => {
      const session = await authSession()
      const token = session.getIdToken().getJwtToken()
      return {
        headers: { authorization: token ? `Bearer ${token}` : '' },
      }
    },*/
  })
