import { RotatingLines } from 'react-loader-spinner'
import './PageSpinner.scss'

const PageSpinner = ({ title, type, children }) => {
  const classType = type ?? 'plain'
  return (
    <>
      <div className={'spinner__container ' + classType}>
        <div className="spinner__panel">
          <div className="spinner__panel__title">
            {children ? (
              children
            ) : (
              <div className="spinner__panel__title__text">
                {title ?? 'Loading...'}
              </div>
            )}
          </div>
          <RotatingLines
            visible={true}
            height="96"
            width="96"
            strokeColor="#3d005c"
            strokeWidth="4"
            animationDuration="0.75"
            ariaLabel="rotating-lines-loading"
            wrapperStyle={{}}
            wrapperClass="spinner__control"
          />
        </div>
      </div>
    </>
  )
}

export default PageSpinner
