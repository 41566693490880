import React from 'react'
import logOut from '../../Assets/Images/log-out.svg'
import './User.scss'
import { authSignOut } from '../../client'

const User = () => {
  const logout = async () => {
    await authSignOut(true)
  }

  return (
    <div className="header-user">
      <div className="header-user__image-wrapper">
        <a href="/logout" style={{ color: 'white', textDecoration: 'none' }}>
          Logout
        </a>
      </div>
      <button
        className="header-user__log-out-button"
        type="button"
        onClick={logout}
      >
        <object
          className="header-user__log-out-icon"
          data={logOut}
          type="image/svg+xml"
        >
          Log Out
        </object>
      </button>
    </div>
  )
}

export default User
