import { useMutation } from 'urql'
import { buildMutationQuery } from '../../client'
import { useEffect, useState } from 'react'
import PageSpinner from '../PageSpinner/PageSpinner'

export const useSubscriptionPortal = () => {
  const [fetching, setFetching] = useState(false)
  const [subscriptionPortalResult, executeSubscriptionPortal] = useMutation(
    buildMutationQuery('subscriptionPortal', ['redirectUrl'])
  )

  const subscriptionPortalUrl = async returnUrl => {
    const result = await executeSubscriptionPortal({
      input: {
        returnUrl,
      },
    })

    return result.data?.subscriptionPortal?.redirectUrl
  }

  const openSubscriptionPortal = async e => {
    if (e) e.preventDefault()
    setFetching(true)

    const url = await subscriptionPortalUrl(
      window.location.origin + `/billing?return=1`
    )
    if (url) {
      setFetching(false)
      window.location.href = url
    }
  }

  return { subscriptionPortalResult, openSubscriptionPortal, fetching }
}

export const SubscriptionPortalRedirect = () => {
  const { openSubscriptionPortal, fetching } = useSubscriptionPortal()

  useEffect(() => {
    if (!fetching && openSubscriptionPortal) {
      openSubscriptionPortal()
    }
  }, [])
  return (
    <PageSpinner type="panel">
      <h3 className="success-title">Redirecting to the billing portal.</h3>
      <span style={{ color: '#676767' }}>
        You will be redirected in a moment.
      </span>
    </PageSpinner>
  )
}

export const SubscriptionPortalLink = ({ children }) => {
  return <a href="/billing-portal">{children}</a>
}
