import React from 'react'
import { Link } from 'react-router-dom'

const BillingAdvanced = () => {
  return (
    <div className="dashboard__main--content">
      <h3 className="content-subtitle">Change Password</h3>
      <p className="content-text">
        You can simply change password for your FlashEdge account.
      </p>
      <Link to="/change-password" className="theme-btn">
        Change Password
      </Link>
      <h3 className="content-subtitle">Delete Account</h3>
      <p className="content-text">
        We're really sorry to see you want to delete your FlashEdge account. If
        something was not clear or complex, try to contact our support first, so
        we can try to help you.
      </p>
      <Link to={'/delete-account'}>
        <button className="theme-btn" type="button">
          Delete Account
        </button>
      </Link>
    </div>
  )
}

export default BillingAdvanced
