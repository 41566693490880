import { useMutation } from 'urql'
import { useStateContext } from '../../Context/ContextProvider'

export const useSubscriptionEndTrial = () => {
  const { setReloadMe } = useStateContext()
  const [subscriptionEndTrialResult, executeSubscriptionEndTrial] = useMutation(
    `mutation SubscriptionEndTrial { 
        subscriptionEndTrial {
          success
        }
    }`
  )

  const subscriptionEndTrial = async e => {
    e.preventDefault()
    const result = await executeSubscriptionEndTrial()

    if (result.data?.subscriptionEndTrial?.success) {
      setTimeout(() => {
        setReloadMe(true)
      }, 2000)
    }
  }

  return { subscriptionEndTrialResult, subscriptionEndTrial }
}

export const SubscriptionEndTrial = ({ children }) => {
  const { subscriptionEndTrial } = useSubscriptionEndTrial()

  return (
    <a href="#end-trial" onClick={subscriptionEndTrial}>
      {children}
    </a>
  )
}
