import React, { useState } from 'react'
import { useStateContext } from '../../Context/ContextProvider'
import { useMutation, useQuery } from 'urql'
import { buildMutationQuery } from '../../client'
import { ActionButton, Buttons } from '../ActionButton/ActionButton'
import { useStorageAPI } from '../../Context/ContextProvider/StorageApi'
import { Link } from 'react-router-dom'
// import './DistMonitoring.scss'

const AccessKeyFragment = `
accessKey {
  accessKeyId
  secretAccessKey
  status
  createdAt
}
`

const AccessKeyQuery = `
  query AccessKeyQuery {
    me {
      account {
        hasAccessKey
        ${AccessKeyFragment}
      }
    }
  }
`

const useAccessKeyQuery = () =>
  useQuery({
    query: AccessKeyQuery,
    pause: false,
  })

const StorageRemoteAccess = () => {
  const {
    me,
    activeStorage,
    isSubscription,
  } = useStateContext()
  const { setReload, currentStorage } = useStorageAPI(activeStorage)

  const [storageAccessGrantResult, storageAccessGrant] = useMutation(
    buildMutationQuery('storageAccessGrant', ['success'])
  )

  const [storageRevokeAccessResult, storageAccessRevoke] = useMutation(
    buildMutationQuery('storageAccessRevoke', ['success'])
  )

  const grantAccess = async () => {
    const result = await storageAccessGrant({
      input: {
        id: activeStorage,
      },
    })

    if (result?.data?.storageAccessGrant?.success) {
      currentStorage.hasAccess = true
    }
  }

  const revokeAccess = async () => {
    const result = await storageAccessRevoke({
      input: {
        id: activeStorage,
      },
    })

    if (result?.data?.storageAccessRevoke?.success) {
      currentStorage.hasAccess = false
    }
  }

  const RenderGrantAccess = () => (
    <>
      <p className="content-text">
        This storage does not have an active remote access
      </p>
      <ActionButton
        label="Grant Access"
        onClick={grantAccess}
        {...storageAccessGrantResult}
      />
    </>
  )

  const RenderGenerateAccessKeys = () =>
    <>
      <p className="content-text">
      To access to the remote storage, you need to generate access keys first. <br />You can generate your keys <Link to='/billing/access-keys'>here</Link>.
      </p>
    </>

  const RenderRemoteAccess = () => {
    const [secret, setSecret] = useState('**********')
    const [accessKeyResult] = useAccessKeyQuery()
    const data = accessKeyResult?.data?.me?.account?.accessKey
    const showSecret = () => {
      setSecret(data?.secretAccessKey)
    }
    return (
      <>
        <form>
          <p className="content-text">
            Configuring storage access depends on the client used on your device. The connection settings you can use are listed below.
          </p>
          <div className="input-wrap">
            <label className="input-label">Protocol</label>
            <input
              className="input"
              type="text"
              placeholder="****"
              value="Amazon S3"
              disabled
              style={{ maxWidth: 500 }}
            />{' '}
          </div>
          <div className="input-wrap">
            <label className="input-label">Server</label>
            <input
              className="input"
              type="text"
              placeholder="****"
              value="https://s3.amazonaws.com"
              disabled
              style={{ maxWidth: 500 }}
            />{' '}
          </div>
          <div className="input-wrap">
            <label className="input-label">Bucket URL</label>
            <input
              className="input"
              type="text"
              placeholder="****"
              value={'https://' + currentStorage?.awsId + '.s3.amazonaws.com'}
              disabled
              style={{ maxWidth: 500 }}
            />{' '}
          </div>
          <div className="input-wrap">
            <label className="input-label">Bucket name</label>
            <input
              className="input"
              type="text"
              placeholder="****"
              value={currentStorage?.awsId}
              disabled
              style={{ maxWidth: 500 }}
            />{' '}
          </div>
          <div className="input-wrap">
            <label className="input-label">Access Key ID</label>
            <input
              className="input"
              type="text"
              placeholder="****"
              value={data?.accessKeyId}
              disabled
              style={{ maxWidth: 500 }}
            />
          </div>
          <div className="input-wrap">
            <label className="input-label">Secret Access Key</label>
            <input
              className="input"
              type="text"
              placeholder="****"
              value={secret}
              disabled
              style={{ maxWidth: 500 }}
            />{' '}
          </div>
        </form>
        <Buttons {...storageRevokeAccessResult}>
          <button
            className="theme-btn form-btn"
            type="button"
            onClick={showSecret}
          >
            Show secret
          </button>
          <a
            href="https://docs.flashedgecdn.com/getting-started-with-flashedge/flashedge-set-up-instructions"
            target="_blank"
            rel="noreferrer"
          >
            <button className="theme-btn" type="button">
              How to connect
            </button>
          </a>
          <ActionButton
            label="Revoke Access"
            onClick={revokeAccess}
            {...storageRevokeAccessResult}
          />
        </Buttons>
      </>
    )
  }

  return (
    <>
      <h3 className="content-subtitle mb-40">FlashEdge Storage</h3>
      {currentStorage?.hasAccess 
        ?  (<RenderRemoteAccess />) 
        : me?.account?.hasAccessKey 
          ? (<RenderGrantAccess />) 
          : (<RenderGenerateAccessKeys />)}
    </>
  )
}

export default StorageRemoteAccess
