import React from 'react'
import { useStateContext } from '../../Context/ContextProvider'
import { useMutation } from 'urql'
import { buildMutationQuery } from '../../client'
import { Link } from 'react-router-dom'
import { SubscriptionGuard } from '../SubscriptionGuard/SubscriptionGuard'

const DistAdvanced = () => {
  const { currentDist, activeDist, setReloadCurrentDist } = useStateContext()

  const [distributionEnableResult, distributionEnable] = useMutation(
    buildMutationQuery('distributionEnable', ['success'])
  )
  const [distributionDisableResult, distributionDisable] = useMutation(
    buildMutationQuery('distributionDisable', ['success'])
  )

  const handleEnable = async () => {
    const result = await distributionEnable({
      input: {
        id: activeDist,
      },
    })

    if (result?.data?.distributionEnable?.success) {
      setReloadCurrentDist(true)
    }
  }

  const handleDisable = async () => {
    const result = await distributionDisable({
      input: {
        id: activeDist,
      },
    })

    if (result?.data?.distributionDisable?.success) {
      setReloadCurrentDist(true)
    }
  }

  return (
    <form className="dashboard__main-form" onSubmit={e => e.preventDefault}>
      <SubscriptionGuard type="form">
        <h3 className="content-subtitle">Invalidate Cache</h3>
        <p className="content-text font-size-18">
          Invalidate files in your distribution cache to delete all currently
          cached content. After the cache is reset, your distribution will pull,
          cache, and serve new content from your origin.
        </p>
        <Link to={`/distributions/${activeDist}/advanced/invalidate`}>
          <button className="theme-btn" type="button">
            Invalidate Cache
          </button>
        </Link>
        <h3 className="content-subtitle">Disable FlashEdge distribution</h3>
        <p className="content-text font-size-18">
          Suspending your FlashEdge distribution will disable it, but you’ll be
          able to get back and enable it again.
        </p>
        {distributionDisableResult.error && (
          <p className={`content-text`}>
            <span className="warning">
              {distributionDisableResult.error.message.replace(
                '[GraphQL] ',
                ''
              )}
            </span>
          </p>
        )}
        {distributionEnableResult.error && (
          <p className={`content-text`}>
            <span className="warning">
              {distributionEnableResult.error.message.replace('[GraphQL] ', '')}
            </span>
          </p>
        )}
        <div className="button-wrapper">
          <button
            className="theme-btn form-btn"
            type="button"
            onClick={handleDisable}
            disabled={
              currentDist.status !== 'ENABLED' ||
              distributionDisableResult.fetching
            }
          >
            {distributionDisableResult.fetching && (
              <svg
                className="spinner"
                viewBox="0 0 50 50"
                width="24"
                height="24"
              >
                <circle
                  className="path"
                  cx="25"
                  cy="25"
                  r="20"
                  fill="none"
                  strokeWidth="5"
                ></circle>
              </svg>
            )}
            Disable
          </button>
          <button
            className="theme-btn form-btn ml-auto-10"
            type="button"
            onClick={handleEnable}
            disabled={
              currentDist.status !== 'DISABLED' ||
              distributionEnableResult.fetching
            }
          >
            {distributionEnableResult.fetching && (
              <svg
                className="spinner"
                viewBox="0 0 50 50"
                width="24"
                height="24"
              >
                <circle
                  className="path"
                  cx="25"
                  cy="25"
                  r="20"
                  fill="none"
                  strokeWidth="5"
                ></circle>
              </svg>
            )}
            Enable
          </button>
        </div>
        <h3 className="content-subtitle">Delete FlashEdge distribution</h3>
        <p className="content-text font-size-18">
          Deleting your FlashEdge distribution will permanently delete it.
          Update the DNS records of your domain before deleting your
          distribution to avoid traffic interruption to your origin.
        </p>
        <Link to={`/distributions/${activeDist}/advanced/delete`}>
          <button
            className="theme-btn"
            type="button"
            disabled={currentDist.status !== 'DISABLED'}
          >
            Delete
          </button>
        </Link>
      </SubscriptionGuard>
    </form>
  )
}

export default DistAdvanced
