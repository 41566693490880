import React, { useEffect, useState } from 'react'
import { useStateContext } from '../../Context/ContextProvider'
import { useMutation, useQuery } from 'urql'
import { useAuthContext } from '../../Context/AuthContext'
import { buildMutationQuery } from '../../client'

const AccessKeyFragment = `
accessKey {
  id
  accessKeyId
  secretAccessKey
  status
  createdAt
}
`

const AccessKeyCreateMutation = `
  mutation AccessKeyCreate {
    accessKeyCreate {
      success
      ${AccessKeyFragment}
    }
  }
`

const AccessKeyQuery = `
  query AccessKeyQuery {
    me {
      account {
        hasAccessKey
        ${AccessKeyFragment}
      }
    }
  }
`

const useAccessKeyQuery = () =>
  useQuery({
    query: AccessKeyQuery,
    pause: true,
  })

const GenerateAccessKey = ({ setAccessKey }) => {
  const { setReloadMe } = useStateContext()
  const [accessKeyCreateResult, accessKeyCreate] = useMutation(
    AccessKeyCreateMutation
  )

  const handleGenerate = async () => {
    const result = await accessKeyCreate()

    if (result?.data?.accessKeyCreate?.success) {
      if (result.data.accessKeyCreate.accessKey) {
        setAccessKey(result.data.accessKeyCreate.accessKey)
        setReloadMe(true)
      }
    }
  }

  return (
    <>
      <p className="content-text">
        Generate an access key that unlock API access, enabling remote access to
        your account resources.
      </p>
      {accessKeyCreateResult.error && (
        <p className={`content-text`}>
          <span className="warning">
            {accessKeyCreateResult.error.message.replace('[GraphQL] ', '')}
          </span>
        </p>
      )}
      <div className="button-wrapper">
        <button
          className="theme-btn form-btn ml-auto-10"
          type="button"
          onClick={handleGenerate}
        >
          {accessKeyCreateResult.fetching && (
            <svg className="spinner" viewBox="0 0 50 50" width="24" height="24">
              <circle
                className="path"
                cx="25"
                cy="25"
                r="20"
                fill="none"
                strokeWidth="5"
              ></circle>
            </svg>
          )}
          Generate access key
        </button>
      </div>
    </>
  )
}

const AccessKeyInfo = ({ data }) => {
  const { setReloadMe } = useStateContext()
  const [secret, setSecret] = useState('**********')
  const showSecret = () => {
    setSecret(data.secretAccessKey)
  }

  const [accessKeyDeleteResult, accessKeyDelete] = useMutation(
    buildMutationQuery('accessKeyDelete', ['success'])
  )

  const handleDelete = async () => {
    const result = await accessKeyDelete({ input: { id: data.id } })

    if (result?.data?.accessKeyDelete?.success) {
      setReloadMe(true)
    }
  }

  return (
    <>
      {data && (
        <form>
          <div className="input-wrap">
            <label className="input-label">AccessKeyId</label>
            <input
              className="input"
              type="text"
              placeholder="****"
              value={data.accessKeyId}
              disabled
              style={{ maxWidth: 500 }}
            />
          </div>
          <div className="input-wrap">
            <label className="input-label">SecretAccessKey</label>
            <input
              className="input"
              type="text"
              placeholder="****"
              value={secret}
              disabled
              style={{ maxWidth: 500 }}
            />{' '}
          </div>
          {accessKeyDeleteResult.error && (
          <p className={`content-text`}>
            <span className="warning">
              {accessKeyDeleteResult.error.message.replace('[GraphQL] ', '')}
            </span>
          </p>
          )}
          <div className="button-wrapper">
            <button
              className="theme-btn form-btn ml-auto-10"
              type="button"
              onClick={showSecret}
            >
              Show secret
            </button>
            <button
              className="theme-btn form-btn ml-auto-10"
              type="button"
              onClick={handleDelete}
            >
              {accessKeyDeleteResult.fetching && (
                <svg className="spinner" viewBox="0 0 50 50" width="24" height="24">
                  <circle
                    className="path"
                    cx="25"
                    cy="25"
                    r="20"
                    fill="none"
                    strokeWidth="5"
                  ></circle>
                </svg>
              )}
              Delete
            </button>
          </div>
        </form>
      )}
    </>
  )
}

const AccessKeys = () => {
  const { me } = useStateContext()
  const [hasAccessKey, setHasAccessKey] = useState()
  const [accessKey, setAccessKey] = useState()

  const [accessKeyResult, executeAccessKeyQuery] = useAccessKeyQuery()

  /*if (me?.account?.hasAccessKey) {
    setHasAccessKey(true)
    executeAccessKeyQuery({ requestPolicy: 'network-only' })
  }*/

  useEffect(() => {
    setHasAccessKey(me.account.hasAccessKey)
    if (me.account.hasAccessKey) {
      executeAccessKeyQuery({ requestPolicy: 'network-only' })
    }
  }, [executeAccessKeyQuery, setHasAccessKey, me?.account?.hasAccessKey])

  useEffect(() => {
    if (accessKeyResult?.data?.me?.account?.accessKey) {
      setAccessKey(accessKeyResult.data.me.account.accessKey)
    }
  }, [setAccessKey, accessKeyResult?.data?.me?.account?.accessKey])

  return (
    <div className="dashboard__main--content">
      <h3 className="content-subtitle">Access Keys</h3>
      {hasAccessKey === true && <AccessKeyInfo data={accessKey} />}
      {hasAccessKey === false && (
        <GenerateAccessKey setAccessKey={setAccessKey} />
      )}
    </div>
  )
}

export default AccessKeys
